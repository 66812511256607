import { Avatar, Box, Flex, Spinner, Stack, Text, useColorModeValue } from '@chakra-ui/react';
import { getHashedAvatarColor, Header, PrimaryLayout, SidebarMenu } from '@main/ui';
import { useAuthenticationStatus, useUserEmail } from '@nhost/react';
import { Outlet } from '@tanstack/react-router';
import { useEffect } from 'react';

import { useVendorAppDispatch, useVendorAppSelector } from '../../hooks/redux-toolkit-hooks';
import { getVendorUserState, vendorPageLoaded } from '../vendor-questionnaires/slice';
import { useVendorDrawer } from './vendor-drawer';
import { useVendorSearchParams, useVendorSidebarMenu } from './vendor-menu';

export const VendorLayout = () => {
  const dispatch = useVendorAppDispatch();
  const email = useUserEmail();
  const { isLoading, isAuthenticated } = useAuthenticationStatus();
  const vendorUserState = useVendorAppSelector(getVendorUserState);
  const { vendorToken } = useVendorSearchParams();
  const { menuItems } = useVendorSidebarMenu();
  const drawerContent = useVendorDrawer();
  const textColor = useColorModeValue('gray.600', 'gray.300');
  const logoColor = useColorModeValue('gray.700', 'gray.100');
  const companyName = email?.split('@')[1] || 'Vendor portal';

  useEffect(() => {
    dispatch(vendorPageLoaded({ vendorToken }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!isAuthenticated && vendorUserState === 'errored') {
    return null;
  }

  if (isLoading || !isAuthenticated || vendorUserState !== 'ready') {
    return (
      <Stack height="100vh" align="center" justify="center" shouldWrapChildren>
        <Spinner size="xl" />
      </Stack>
    );
  }

  return (
    <PrimaryLayout
      logoLink="/vendor/$vendorToken"
      sidebar={({ isSidebarCollapsed, onSidebarClose }) => (
        <Flex h="full" direction="column">
          <Box overflowY="auto" flex={1} mb={4}>
            <Box px={3} py={5}>
              <Flex minWidth={0} grow={1} alignItems="center">
                <Avatar mr="3" size="sm" name={companyName} bgColor="purple.100" color="blue.500" />
                <Flex
                  direction="column"
                  grow={1}
                  textOverflow="ellipsis"
                  whiteSpace="nowrap"
                  overflow="hidden"
                >
                  <Box py={1} rounded="md" isTruncated>
                    <Text fontSize="sm" textAlign="left" fontWeight="semibold" color={logoColor}>
                      {companyName}
                    </Text>
                  </Box>
                </Flex>
              </Flex>
            </Box>
            <SidebarMenu
              items={menuItems}
              onClick={onSidebarClose}
              isSidebarCollapsed={isSidebarCollapsed}
            />
          </Box>
        </Flex>
      )}
      header={({ logo, onSidebarOpen }) => (
        <Header
          logo={logo}
          onSidebarToggle={onSidebarOpen}
          menuContent={
            <Flex alignItems="center">
              <Avatar name={email} size="sm" {...getHashedAvatarColor(email)} />
              <Text
                flexGrow={1}
                px="2"
                fontSize="xs"
                color={textColor}
                display={{ base: 'none', md: 'block' }}
              >
                {email}
              </Text>
            </Flex>
          }
        />
      )}
      drawer={drawerContent}
    >
      <Outlet />
    </PrimaryLayout>
  );
};
