import { ClipboardDocumentListIcon } from '@heroicons/react/24/outline';
import { menuItem } from '@main/ui';
import { useParams } from '@tanstack/react-router';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export function useVendorSidebarMenu() {
  const { t } = useTranslation();
  const params = useVendorSearchParams();

  const menuItems = useMemo(
    () => [
      menuItem({
        label: t('questionnaires.breadcrumbTitle'),
        to: '/$vendorToken/questionnaires',
        params,
        icon: ClipboardDocumentListIcon,
      }),
    ],
    [params, t],
  );

  return { menuItems };
}

export function useVendorSearchParams() {
  return useParams({ from: '/$vendorToken' });
}
