import { HStack, Stack, Tag, Text } from '@chakra-ui/react';
import { datadogLogs } from '@datadog/browser-logs';
import {
  ArrowUpOnSquareStackIcon,
  ListBulletIcon,
  QueueListIcon,
} from '@heroicons/react/24/outline';
import { useMarkVendorQuestionnaireAsSubmittedMutation } from '@main/graphql/mutations/MarkVendorQuestionnaireAsSubmitted.vendor.generated';
import { Vendor_Questionnaire_Statuses_Enum } from '@main/graphql/types.vendor.generated';
import { QuestionnnaireFormStats } from '@main/questionnaires-form';
import { QUESTIONNAIRE_STATUSES } from '@main/shared/types';
import {
  getQuestionnaireType,
  QuestionnaireType,
  toError,
  useStableCallback,
  VendorQuestionnaireTabKeys,
} from '@main/shared/utils';
import {
  Drawer,
  DrawerActionsProps,
  errorToast,
  getVisibleTabs,
  StatusTag,
  successToast,
  useAlertDialog,
} from '@main/ui';
import { RefObject, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { VendorQuestionnaireDetailsTab } from './details-tab';
import {
  GetVendorQuestionnaireDrawerQuery,
  useGetVendorQuestionnaireDrawerQuery,
} from './drawer.vendor.generated';
import { VendorQuestionnaireQuestionsTab } from './questions-tab';
import { VendorQuestionnaireSubmissionTab } from './submission-tab';

export interface VendorQuestionnaireDrawerProps {
  questionnaireId: string;
}

export function VendorQuestionnaireDrawer({ questionnaireId }: VendorQuestionnaireDrawerProps) {
  const { t } = useTranslation();
  const { data, isLoading } = useGetVendorQuestionnaireDrawerQuery({ questionnaireId });

  const questionnaire = data?.vendor_questionnaires_by_pk;
  const isSubmitted = questionnaire?.status === Vendor_Questionnaire_Statuses_Enum.Submitted;

  const [questionnaireFormStats, setQuestionnaireFormStats] = useState<QuestionnnaireFormStats>({
    total: 0,
    completed: 0,
  });
  const validator = useRef<() => Promise<boolean>>();
  const setValidator = useStableCallback(
    (validatorFn: () => Promise<boolean>) => (validator.current = validatorFn),
  );
  const drawerActions = useDrawerActions({
    questionnaireId,
    questionnaireFormStats,
    onValidate: validator.current,
  });
  const tabsRef = useRef<HTMLDivElement>(null);
  const { tabs } = useVendorQuestionnaireDrawerTabs(
    questionnaire,
    tabsRef,
    setQuestionnaireFormStats,
    setValidator,
  );
  const [wasSubmitted, setWasSubmitted] = useState(isSubmitted);

  useEffect(() => {
    if (!wasSubmitted && isSubmitted) {
      setWasSubmitted(true);
    } else if (wasSubmitted && !isSubmitted) {
      setWasSubmitted(false);
      errorToast(t('vendors.questionnaires.toasts.submitFailed'));
    }
  }, [wasSubmitted, isSubmitted, t]);

  return (
    <Drawer.Layout isLoading={isLoading} canView={true} isNotFound={!questionnaire}>
      <Drawer.Toolbar
        {...drawerActions}
        info={t('vendors.questionnaires.drawer.completedQuestions', questionnaireFormStats)}
      />
      <Drawer.Tabs tabs={tabs} isLazy={false} ref={tabsRef}>
        <VendorQuestionnaireDrawerHeader questionnaireId={questionnaireId} />
      </Drawer.Tabs>
    </Drawer.Layout>
  );
}

function useDrawerActions({
  questionnaireId,
  questionnaireFormStats,
  onValidate,
}: {
  questionnaireId: string;
  questionnaireFormStats: QuestionnnaireFormStats;
  onValidate?(): Promise<boolean>;
}): DrawerActionsProps {
  const { t } = useTranslation();
  const { openDialog } = useAlertDialog();
  const { data, refetch } = useGetVendorQuestionnaireDrawerQuery({ questionnaireId });
  const [submitVendorQuestionnaire, { isLoading: isSubmitting }] =
    useMarkVendorQuestionnaireAsSubmittedMutation();
  const questionnaire = data?.vendor_questionnaires_by_pk;
  const type = getQuestionnaireType(questionnaire?.questionnaire);

  if (
    type !== QuestionnaireType.Form ||
    questionnaire?.status === Vendor_Questionnaire_Statuses_Enum.Submitted
  ) {
    return {};
  }

  const isQuestionnareFormCompleted =
    questionnaireFormStats.total === questionnaireFormStats.completed;

  async function submitFormQuestionnaire() {
    if (!isQuestionnareFormCompleted) {
      return;
    }

    if (onValidate && !(await onValidate())) {
      return;
    }

    try {
      await submitVendorQuestionnaire({ vendorQuestionnaireId: questionnaireId }).unwrap();
      successToast(t('vendors.questionnaires.toasts.submitSuccess'));
      await refetch().unwrap();
    } catch (error) {
      errorToast(t('vendors.questionnaires.toasts.submitFailed'));
      datadogLogs.logger.error(
        'Error while submitting vendor questionnaire',
        { questionnaireId },
        toError(error),
      );
    }
  }

  return {
    primaryAction: {
      label: t('vendors.questionnaires.drawer.submit'),
      isDisabled: !isQuestionnareFormCompleted,
      isLoading: isSubmitting,
      onClick: () =>
        openDialog({
          dialogHeader: t('vendors.questionnaires.submitAlert.header'),
          dialogContent: t('vendors.questionnaires.submitAlert.content'),
          confirmAction: {
            colorScheme: 'blue',
            children: t('buttons.submit'),
            onClick: submitFormQuestionnaire,
          },
        }),
    },
  };
}

const VendorQuestionnaireDrawerHeader = ({ questionnaireId }: { questionnaireId: string }) => {
  const { t } = useTranslation();
  const { data } = useGetVendorQuestionnaireDrawerQuery({ questionnaireId });
  const questionnaire = data?.vendor_questionnaires_by_pk;
  const statusMeta =
    QUESTIONNAIRE_STATUSES[questionnaire?.status ?? Vendor_Questionnaire_Statuses_Enum.Pending];

  return (
    <Stack spacing={4}>
      <HStack spacing={2}>
        <Tag colorScheme="purple">{t('entities.questionnaire')}</Tag>
        <StatusTag
          size="sm"
          minW="auto"
          maxW="none"
          colorScheme={statusMeta.colorScheme}
          data-testid="vendor-questionnaire-status"
        >
          {t(statusMeta.value)}
        </StatusTag>
      </HStack>

      <Text fontSize="xl" fontWeight="bold">
        {questionnaire?.questionnaire.name}
      </Text>
    </Stack>
  );
};

const useVendorQuestionnaireDrawerTabs = (
  questionnaire: GetVendorQuestionnaireDrawerQuery['vendor_questionnaires_by_pk'],
  tabsRef: RefObject<HTMLDivElement>,
  onStatsChange?: (stats: QuestionnnaireFormStats) => void,
  getValidator?: (validator: () => Promise<boolean>) => void,
) => {
  const { t } = useTranslation();

  const tabs = useMemo(() => {
    if (!questionnaire) {
      return [];
    }

    const type = getQuestionnaireType(questionnaire.questionnaire);

    return getVisibleTabs<VendorQuestionnaireTabKeys>({
      details: {
        label: t('vendors.questionnaires.tabs.details.title'),
        icon: ListBulletIcon,
        panel: <VendorQuestionnaireDetailsTab questionnaireId={questionnaire.id} />,
        hideTabTitle: true,
      },
      files: {
        label: t('vendors.questionnaires.tabs.submission.title'),
        icon: ArrowUpOnSquareStackIcon,
        panel: <VendorQuestionnaireSubmissionTab questionnaireId={questionnaire.id} />,
        isHidden: type !== QuestionnaireType.File,
      },
      form: {
        label: t('vendors.questionnaires.tabs.questions.title'),
        hideTabTitle: true,
        icon: QueueListIcon,
        panel: (
          <VendorQuestionnaireQuestionsTab
            questionnaireId={questionnaire.id}
            tabsRef={tabsRef}
            onStatsChange={onStatsChange}
            getValidator={getValidator}
          />
        ),
        isHidden: type !== QuestionnaireType.Form,
      },
    });
  }, [questionnaire, t, tabsRef, onStatsChange, getValidator]);

  return { tabs };
};
