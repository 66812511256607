import { datadogLogs } from '@datadog/browser-logs';
import { DynamicFormRef, QuestionnaireComponentKind } from '@main/dynamic-form';
import {
  Vendor_Questionnaire_Statuses_Enum,
  Vq_Form_Upload_Types_Enum,
} from '@main/graphql/types.vendor.generated';
import {
  isAnswerFile,
  QuestionnnaireForm,
  QuestionnnaireFormFieldAnswer,
  QuestionnnaireFormMode,
  QuestionnnaireFormStats,
} from '@main/questionnaires-form';
import { toError, useStableCallback } from '@main/shared/utils';
import { errorToast } from '@main/ui';
import useDebounceFn from 'ahooks/es/useDebounceFn';
import { RefObject, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { useVendorAppDispatch } from '../../hooks/redux-toolkit-hooks';
import {
  GetVendorQuestionnaireDrawerQuery,
  useGetVendorQuestionnaireDrawerQuery,
} from './drawer.vendor.generated';
import {
  api as questionsTabApi,
  useDeleteVendorQuestionnaireAnswerFileMutation,
} from './questions-tab.vendor.generated';

export interface VendorQuestionnaireQuestionsTabProps {
  questionnaireId: string;
  tabsRef: RefObject<HTMLDivElement>;
  getValidator?: (validator: () => Promise<boolean>) => void;
  onStatsChange?(stats: QuestionnnaireFormStats): void;
}

export function VendorQuestionnaireQuestionsTab({
  questionnaireId,
  tabsRef,
  getValidator,
  onStatsChange,
}: VendorQuestionnaireQuestionsTabProps) {
  const { data } = useGetVendorQuestionnaireDrawerQuery({ questionnaireId });
  const formRef = useRef<DynamicFormRef>(null);
  const form = formRef.current?.form;

  useEffect(
    () => getValidator?.(() => form?.trigger() ?? Promise.resolve(true)),
    [getValidator, form],
  );

  const questionnaire = data?.vendor_questionnaires_by_pk;

  const { answers, onAnswerChange } = useQuestionnaireAnswers({ questionnaireId, questionnaire });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fields = useMemo(() => questionnaire?.form?.config_snapshot ?? [], [questionnaire?.id]);

  if (!questionnaire || !questionnaire.form?.config_snapshot) {
    return null;
  }

  const isSubmitted = questionnaire.status === Vendor_Questionnaire_Statuses_Enum.Submitted;

  return (
    <QuestionnnaireForm
      mode={QuestionnnaireFormMode.Answer}
      fields={fields}
      answers={answers}
      uploads={questionnaire.questionnaire.questionnaire_uploads}
      isSubmitted={isSubmitted}
      onAnswerChange={onAnswerChange}
      onStatsChange={onStatsChange}
      vScrollRef={tabsRef}
      vScrollOffset={288}
      vScrollSize={220}
      ref={formRef}
    />
  );
}

interface UseQuestionnaireAnswersProps {
  questionnaireId: string;
  questionnaire: GetVendorQuestionnaireDrawerQuery['vendor_questionnaires_by_pk'];
}

interface UpdatedAnswerRefObj extends Partial<QuestionnnaireFormFieldAnswer> {
  updateCounter?: number;
  lastUpdatedCounter?: number;
}

function useQuestionnaireAnswers({ questionnaireId, questionnaire }: UseQuestionnaireAnswersProps) {
  const { t } = useTranslation();
  const { refetch } = useGetVendorQuestionnaireDrawerQuery({ questionnaireId });
  const dispatch = useVendorAppDispatch();
  const [deleteAnswerFiles] = useDeleteVendorQuestionnaireAnswerFileMutation();
  const answerUpdateRef = useRef<Map<string, UpdatedAnswerRefObj>>(new Map());
  const configMap = useMemo(
    () => new Map(questionnaire?.form?.config_snapshot?.map((field) => [field.name, field])),
    [questionnaire?.form?.config_snapshot],
  );

  const updateAnswer = useStableCallback(async () => {
    const updatedAnswers = Array.from(answerUpdateRef.current.values()).filter(
      (answer) => answer.lastUpdatedCounter !== answer.updateCounter,
    );

    const shouldRefetch = updatedAnswers.some(
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      (answer) => configMap.get(answer.field_name!)?.kind === QuestionnaireComponentKind.Options,
    );

    await Promise.all(
      updatedAnswers.map(async (answer) => {
        answer.lastUpdatedCounter = answer.updateCounter;

        return dispatch(
          questionsTabApi.endpoints.CreateVendorQuestionnaireAnswer.initiate({
            answer: {
              field_name: answer.field_name,
              status: answer.status,
              value: answer.value,
              comment: answer.comment,
              vendor_questionnaire_form_id: questionnaire?.form?.id,
            },
          }),
        ).unwrap();
      }),
    );

    if (shouldRefetch) {
      await refetch().unwrap();
    }
  });

  const debouncedUpdateAnswer = useDebounceFn(updateAnswer, { wait: 250 });

  const onCreateNewAnswer = useStableCallback(
    async (
      answer: QuestionnnaireFormFieldAnswer,
      change: Partial<QuestionnnaireFormFieldAnswer>,
    ) => {
      if (!questionnaire) {
        return;
      }

      const currAnswer = {
        ...answer,
        ...answerUpdateRef.current.get(answer.field_name),
      };

      answerUpdateRef.current.set(answer.field_name, {
        ...answer,
        ...answerUpdateRef.current.get(answer.field_name),
        ...change,
        updateCounter: (answerUpdateRef.current.get(answer.field_name)?.updateCounter ?? 0) + 1,
      });

      const deletedFiles =
        (change.support_files &&
          answer.support_files?.filter(
            (prevUpload) => !change.support_files?.find((upload) => upload.id === prevUpload.id),
          )) ||
        [];

      const valueChanged = 'value' in change;
      const answerFile = isAnswerFile(change.value) ? change.value.file : null;
      let didAnswerFileChange = false;

      if (valueChanged && !answerFile && isAnswerFile(currAnswer.value)) {
        didAnswerFileChange = true;
        deletedFiles.push(currAnswer.value.file);
      }

      if (deletedFiles.length) {
        await deleteAnswerFiles({
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          uploadIds: deletedFiles.map((upload) => upload.id!),
        }).unwrap();

        // we don't have to update the answer for file deletion operation alone
        if (!didAnswerFileChange) {
          await refetch().unwrap();
          return;
        }
      }

      const addedFiles =
        change.support_files
          ?.filter(
            (upload) =>
              !!upload.file_id &&
              !answer.support_files?.find((prevUpload) => prevUpload.id === upload.id),
          )
          .map((upload) => ({
            file_id: upload.file_id,
            type: Vq_Form_Upload_Types_Enum.Support,
          })) ?? [];

      if (
        valueChanged &&
        answerFile &&
        (!isAnswerFile(currAnswer.value) || answerFile.id !== currAnswer.value.file.id)
      ) {
        didAnswerFileChange = true;
        addedFiles.push({
          file_id: answerFile.id,
          type: Vq_Form_Upload_Types_Enum.Answer,
        });
      }

      if (addedFiles.length) {
        dispatch(
          questionsTabApi.endpoints.AddVendorQuestionnaireAnswerFile.initiate({
            answer: {
              field_name: answer.field_name,
              vendor_questionnaire_form_id: questionnaire?.form?.id,
              form_uploads: { data: addedFiles },
            },
          }),
        );

        // we don't have to update the answer for file addition operation alone
        if (!didAnswerFileChange) {
          await refetch().unwrap();
          return;
        }
      }

      const shouldDebounce = ('value' in change || 'comment' in change) && !didAnswerFileChange;

      if (shouldDebounce) {
        await debouncedUpdateAnswer.run();
      } else {
        await updateAnswer();
      }

      const shouldRefetch = 'status' in change || 'form_uploads' in change || didAnswerFileChange;

      if (shouldRefetch) {
        await refetch().unwrap();
      }
    },
  );

  const onAnswerChange = useStableCallback(
    async (
      answer: QuestionnnaireFormFieldAnswer,
      change: Partial<QuestionnnaireFormFieldAnswer>,
    ) => {
      try {
        await onCreateNewAnswer(answer, change);
      } catch (error) {
        errorToast(
          t('errorMessages.updateFailed', {
            entity: t('entities.vendorQuestionnaireFormAnswer'),
          }),
        );
        datadogLogs.logger.error(
          'Error while saving vendor questionnaire answer',
          { questionnaireId },
          toError(error),
        );
      }
    },
  );

  return { answers: questionnaire?.form?.answers ?? [], onAnswerChange: onAnswerChange };
}
