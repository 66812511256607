/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.vendor.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
import { UserNameEmailFragmentFragmentDoc } from '../../../../../libs/graphql/src/fragments/UserNameEmailFragment.vendor.generated';
import { FileInfoFragmentFragmentDoc } from '../../../../../libs/graphql/src/fragments/FileInfoFragment.vendor.generated';
import { QuestionnaireUploadFragmentDoc } from '../../../../../libs/graphql/src/fragments/QuestionnaireUploadFragment.vendor.generated';
import { api } from '@main/graphql/shared-api';
export type GetVendorQuestionnaireDrawerQueryVariables = Types.Exact<{
  questionnaireId: Types.Scalars['uuid']['input'];
}>;

export type GetVendorQuestionnaireDrawerQuery = {
  __typename?: 'query_root';
  vendor_questionnaires_by_pk?: {
    __typename?: 'vendor_questionnaires';
    id: string;
    status: Types.Vendor_Questionnaire_Statuses_Enum;
    due_date?: string;
    sent_by_user: { __typename?: 'users'; id: string; email?: string; displayName: string };
    questionnaire: {
      __typename?: 'questionnaires';
      id: string;
      name?: string;
      description?: string;
      file_id?: string;
      form_id?: string;
      file?: { __typename?: 'files'; id: string; name?: string; size?: number };
      questionnaire_uploads: Array<{
        __typename?: 'questionnaire_uploads';
        id: string;
        file: { __typename?: 'files'; id: string; name?: string; size?: number };
      }>;
    };
    answer_upload?: {
      __typename?: 'vendor_questionnaire_uploads';
      id: string;
      file: { __typename?: 'files'; id: string; name?: string; size?: number };
    };
    uploads: Array<{
      __typename?: 'vendor_questionnaire_uploads';
      id: string;
      file: { __typename?: 'files'; id: string; name?: string; size?: number };
    }>;
    form?: {
      __typename?: 'vendor_questionnaire_forms';
      id: string;
      config_snapshot?: ClientTypes.FormConfig;
      answers: Array<{
        __typename?: 'vendor_questionnaire_form_answers';
        id: string;
        updated_at: string;
        field_name: string;
        status: Types.Vendor_Questionnaire_Form_Answer_Statuses_Enum;
        value?: ClientTypes.VendorQuestionnaireFormAnswerValue;
        comment?: string;
        support_files: Array<{
          __typename?: 'vendor_questionnaire_form_uploads';
          id: string;
          file: { __typename?: 'files'; id: string; name?: string; size?: number };
        }>;
        answer_files: Array<{
          __typename?: 'vendor_questionnaire_form_uploads';
          id: string;
          file: { __typename?: 'files'; id: string; name?: string; size?: number };
        }>;
      }>;
    };
  };
};

export const GetVendorQuestionnaireDrawerDocument = `
    query GetVendorQuestionnaireDrawer($questionnaireId: uuid!) {
  vendor_questionnaires_by_pk(id: $questionnaireId) {
    id
    status
    due_date
    sent_by_user {
      ...UserNameEmailFragment
    }
    questionnaire {
      id
      name
      description
      file_id
      form_id
      file {
        ...FileInfoFragment
      }
      questionnaire_uploads {
        ...QuestionnaireUpload
      }
    }
    answer_upload {
      id
      file {
        ...FileInfoFragment
      }
    }
    uploads {
      id
      file {
        ...FileInfoFragment
      }
    }
    form {
      id
      config_snapshot
      answers {
        id
        updated_at
        field_name
        status
        value
        comment
        support_files: form_uploads(where: {type: {_eq: Support}}) {
          id
          file {
            ...FileInfoFragment
          }
        }
        answer_files: form_uploads(where: {type: {_eq: Answer}}) {
          id
          file {
            ...FileInfoFragment
          }
        }
      }
    }
  }
}
    ${UserNameEmailFragmentFragmentDoc}
${FileInfoFragmentFragmentDoc}
${QuestionnaireUploadFragmentDoc}` as string &
  TypedDocumentNode<GetVendorQuestionnaireDrawerQuery, GetVendorQuestionnaireDrawerQueryVariables>;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    GetVendorQuestionnaireDrawer: build.query<
      GetVendorQuestionnaireDrawerQuery,
      GetVendorQuestionnaireDrawerQueryVariables
    >({
      query: (variables) => ({ document: GetVendorQuestionnaireDrawerDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetVendorQuestionnaireDrawerQuery, useLazyGetVendorQuestionnaireDrawerQuery } =
  injectedRtkApi;
