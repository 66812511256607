/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.vendor.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
export type UserNameEmailFragmentFragment = {
  __typename?: 'users';
  id: string;
  email?: string;
  displayName: string;
};

export const UserNameEmailFragmentFragmentDoc = `
    fragment UserNameEmailFragment on users {
  id
  email
  displayName
}
    `;
