import { datadogLogs } from '@datadog/browser-logs';
import { toError } from '@main/shared/utils';
import {
  isInvalidRefreshTokenError,
  loginWithRefreshToken,
} from '@main/shared/utils/login-with-refresh-token';
import { toast } from '@main/ui';
import { User } from '@nhost/nhost-js';
import { createListenerMiddleware, TaskAbortError, TypedStartListening } from '@reduxjs/toolkit';
import posthog from 'posthog-js';

import { VendorAppDispatch, VendorAppRootState } from '../../store';
import { nhost } from '../../utils/nhostClient';
import { vendorPageLoaded, vendorUserErrored, vendorUserReady } from './slice';

export const vendorListenerMiddleware = createListenerMiddleware();

const startListening = vendorListenerMiddleware.startListening as TypedStartListening<
  VendorAppRootState,
  VendorAppDispatch
>;

startListening({
  actionCreator: vendorPageLoaded,
  effect: async (action, api) => {
    api.unsubscribe();
    const { vendorToken } = action.payload;

    try {
      const user = await loginWithRefreshToken(vendorToken, nhost);

      await prepareUser(user);

      api.dispatch(vendorUserReady());
    } catch (e) {
      if (e instanceof TaskAbortError) {
        return;
      }

      if (isInvalidRefreshTokenError(e)) {
        toast({
          status: 'error',
          isClosable: true,
          duration: null,
          title:
            'The link has expired or been revoked. If you have a questionnaire to submit, please contact the sender of the email.',
        });

        api.dispatch(vendorUserErrored());
        return;
      }

      toast({
        status: 'error',
        title: 'Something went wrong during login, please try again.',
      });
      api.dispatch(vendorUserErrored());

      datadogLogs.logger.error(`Unable to login vendor user ${vendorToken}:`, {}, toError(e));
    } finally {
      api.subscribe();
    }
  },
});

async function prepareUser(user: User) {
  datadogLogs.setUser({ id: user.id });
  const dContext = datadogLogs.getInternalContext();

  posthog.identify(user.id, {
    email: user.email,
    userId: user.id,
    datadogSessionId: dContext?.session_id,
    appEnv: import.meta.env.VITE_APP_ENV,
  });
}
