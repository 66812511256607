/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.vendor.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
export type FileInfoFragmentFragment = {
  __typename?: 'files';
  id: string;
  name?: string;
  size?: number;
};

export const FileInfoFragmentFragmentDoc = `
    fragment FileInfoFragment on files {
  id
  name
  size
}
    `;
