/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as ClientTypes from './client-scalars';
export type Maybe<T> = T;
export type InputMaybe<T> = T | null | undefined;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = {
  [_ in K]?: never;
};
export type Incremental<T> =
  | T
  | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  AuditTableActions: {
    input: ClientTypes.AuditTableActions;
    output: ClientTypes.AuditTableActions;
  };
  AuditTableHasuraUser: {
    input: ClientTypes.AuditTableHasuraUser;
    output: ClientTypes.AuditTableHasuraUser;
  };
  ClientQquestionMetadata: {
    input: ClientTypes.ClientQquestionMetadata;
    output: ClientTypes.ClientQquestionMetadata;
  };
  ColumnFiltersState: {
    input: ClientTypes.ColumnFiltersState;
    output: ClientTypes.ColumnFiltersState;
  };
  CommentsFragment: { input: any; output: any };
  ControlChangedFields: {
    input: ClientTypes.ControlChangedFields;
    output: ClientTypes.ControlChangedFields;
  };
  ControlDetails: { input: any; output: any };
  ControlEvidenceStatus: {
    input: ClientTypes.ControlEvidenceStatus;
    output: ClientTypes.ControlEvidenceStatus;
  };
  ControlHistoryTables: {
    input: ClientTypes.ControlHistoryTables;
    output: ClientTypes.ControlHistoryTables;
  };
  ControlRowData: { input: ClientTypes.ControlRowData; output: ClientTypes.ControlRowData };
  ControlStatus: { input: ClientTypes.ControlStatus; output: ClientTypes.ControlStatus };
  ControlTags: { input: ClientTypes.ControlTags; output: ClientTypes.ControlTags };
  DateFieldValue: { input: any; output: any };
  EmailFieldValue: { input: any; output: any };
  EvidenceIntegrationConfig: {
    input: ClientTypes.EvidenceIntegrationConfig;
    output: ClientTypes.EvidenceIntegrationConfig;
  };
  EvidenceProgram: { input: any; output: any };
  EvidenceStatus: { input: ClientTypes.EvidenceStatus; output: ClientTypes.EvidenceStatus };
  FieldConfig: { input: any; output: any };
  FieldValue: { input: any; output: any };
  FileInfoFragment: { input: any; output: any };
  FindingTypesEnum: { input: ClientTypes.FindingTypesEnum; output: ClientTypes.FindingTypesEnum };
  FormConfig: { input: ClientTypes.FormConfig; output: ClientTypes.FormConfig };
  NotificationItem: { input: any; output: any };
  NotificationParams: {
    input: ClientTypes.NotificationParams;
    output: ClientTypes.NotificationParams;
  };
  NumberFieldValue: { input: any; output: any };
  OrgIntegrationConfig: {
    input: ClientTypes.OrgIntegrationConfig;
    output: ClientTypes.OrgIntegrationConfig;
  };
  OrganizationDetailsFragment: { input: any; output: any };
  OrganizationEvidence: { input: any; output: any };
  OrganizationUserDetails: { input: any; output: any };
  PolicyChangedFields: {
    input: ClientTypes.PolicyChangedFields;
    output: ClientTypes.PolicyChangedFields;
  };
  PolicyDrawerFragment: { input: any; output: any };
  PolicyHistoryTables: {
    input: ClientTypes.PolicyHistoryTables;
    output: ClientTypes.PolicyHistoryTables;
  };
  PolicyRowData: { input: ClientTypes.PolicyRowData; output: ClientTypes.PolicyRowData };
  QuestionnaireUpload: { input: any; output: any };
  ReviewDetails: { input: any; output: any };
  RiskAssessmentStatus: {
    input: ClientTypes.RiskAssessmentStatus;
    output: ClientTypes.RiskAssessmentStatus;
  };
  RiskChangedFields: {
    input: ClientTypes.RiskChangedFields;
    output: ClientTypes.RiskChangedFields;
  };
  RiskHistoryTables: {
    input: ClientTypes.RiskHistoryTables;
    output: ClientTypes.RiskHistoryTables;
  };
  RiskRowData: { input: ClientTypes.RiskRowData; output: ClientTypes.RiskRowData };
  RoleDetails: { input: any; output: any };
  SelectFieldConfig: { input: any; output: any };
  SelectFieldOption: { input: any; output: any };
  SelectFieldValue: { input: any; output: any };
  SortingState: { input: ClientTypes.SortingState; output: ClientTypes.SortingState };
  TaskDrawerFragment: { input: any; output: any };
  TaskStatus: { input: ClientTypes.TaskStatus; output: ClientTypes.TaskStatus };
  TaskTableFragment: { input: any; output: any };
  TextFieldValue: { input: any; output: any };
  UrlFieldValue: { input: any; output: any };
  UserMetaData: { input: ClientTypes.UserMetaData; output: ClientTypes.UserMetaData };
  UserNameEmailFragment: { input: any; output: any };
  VendorAssessmentStatus: {
    input: ClientTypes.VendorAssessmentStatus;
    output: ClientTypes.VendorAssessmentStatus;
  };
  VendorChangedFields: {
    input: ClientTypes.VendorChangedFields;
    output: ClientTypes.VendorChangedFields;
  };
  VendorHistoryTables: {
    input: ClientTypes.VendorHistoryTables;
    output: ClientTypes.VendorHistoryTables;
  };
  VendorQuestionnaireFormAnswerValue: {
    input: ClientTypes.VendorQuestionnaireFormAnswerValue;
    output: ClientTypes.VendorQuestionnaireFormAnswerValue;
  };
  VendorRowData: { input: ClientTypes.VendorRowData; output: ClientTypes.VendorRowData };
  citext: { input: string; output: string };
  jsonb: { input: any; output: any };
  timestamptz: { input: string; output: string };
  uuid: { input: string; output: string };
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Boolean']['input']>;
  _gt?: InputMaybe<Scalars['Boolean']['input']>;
  _gte?: InputMaybe<Scalars['Boolean']['input']>;
  _in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['Boolean']['input']>;
  _lte?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Scalars['Boolean']['input']>;
  _nin?: InputMaybe<Array<Scalars['Boolean']['input']>>;
};

export type CreateManualFindingInput = {
  __typename?: 'CreateManualFindingInput';
  type: Scalars['FindingTypesEnum']['output'];
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Int']['input']>;
  _gt?: InputMaybe<Scalars['Int']['input']>;
  _gte?: InputMaybe<Scalars['Int']['input']>;
  _in?: InputMaybe<Array<Scalars['Int']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['Int']['input']>;
  _lte?: InputMaybe<Scalars['Int']['input']>;
  _neq?: InputMaybe<Scalars['Int']['input']>;
  _nin?: InputMaybe<Array<Scalars['Int']['input']>>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['String']['input']>;
  _gt?: InputMaybe<Scalars['String']['input']>;
  _gte?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']['input']>;
  _in?: InputMaybe<Array<Scalars['String']['input']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']['input']>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']['input']>;
  _lt?: InputMaybe<Scalars['String']['input']>;
  _lte?: InputMaybe<Scalars['String']['input']>;
  _neq?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']['input']>;
  _nin?: InputMaybe<Array<Scalars['String']['input']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']['input']>;
};

export type Audit_Control_History_View = {
  __typename?: 'audit_control_history_view';
  action: Scalars['AuditTableActions']['output'];
  changed_fields?: Maybe<Scalars['ControlChangedFields']['output']>;
  hasura_user: Scalars['AuditTableHasuraUser']['output'];
  row_data: Scalars['ControlRowData']['output'];
  table_name: Scalars['ControlHistoryTables']['output'];
};

export type Audit_Policy_History_View = {
  __typename?: 'audit_policy_history_view';
  action: Scalars['AuditTableActions']['output'];
  changed_fields?: Maybe<Scalars['PolicyChangedFields']['output']>;
  hasura_user: Scalars['AuditTableHasuraUser']['output'];
  row_data: Scalars['PolicyRowData']['output'];
  table_name: Scalars['PolicyHistoryTables']['output'];
};

export type Audit_Risk_History_View = {
  __typename?: 'audit_risk_history_view';
  action: Scalars['AuditTableActions']['output'];
  changed_fields?: Maybe<Scalars['RiskChangedFields']['output']>;
  hasura_user: Scalars['AuditTableHasuraUser']['output'];
  row_data: Scalars['RiskRowData']['output'];
  table_name: Scalars['RiskHistoryTables']['output'];
};

export type Audit_Vendor_History_View = {
  __typename?: 'audit_vendor_history_view';
  action: Scalars['AuditTableActions']['output'];
  changed_fields?: Maybe<Scalars['VendorChangedFields']['output']>;
  hasura_user: Scalars['AuditTableHasuraUser']['output'];
  row_data: Scalars['VendorRowData']['output'];
  table_name: Scalars['VendorHistoryTables']['output'];
};

/** Boolean expression to compare columns of type "citext". All fields are combined with logical 'AND'. */
export type Citext_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['citext']['input']>;
  _gt?: InputMaybe<Scalars['citext']['input']>;
  _gte?: InputMaybe<Scalars['citext']['input']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['citext']['input']>;
  _in?: InputMaybe<Array<Scalars['citext']['input']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['citext']['input']>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['citext']['input']>;
  _lt?: InputMaybe<Scalars['citext']['input']>;
  _lte?: InputMaybe<Scalars['citext']['input']>;
  _neq?: InputMaybe<Scalars['citext']['input']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['citext']['input']>;
  _nin?: InputMaybe<Array<Scalars['citext']['input']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['citext']['input']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['citext']['input']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['citext']['input']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['citext']['input']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['citext']['input']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['citext']['input']>;
};

export type Client_Questionnaire_Questions = {
  __typename?: 'client_questionnaire_questions';
  metadata?: Maybe<Scalars['ClientQquestionMetadata']['output']>;
};

export type Control_Evidences = {
  __typename?: 'control_evidences';
  status: Scalars['ControlEvidenceStatus']['output'];
};

export type Control_Tags_View = {
  __typename?: 'control_tags_view';
  key: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type Controls = {
  __typename?: 'controls';
  status: Scalars['ControlStatus']['output'];
  tags: Scalars['ControlTags']['output'];
};

/** ordering argument of a cursor */
export enum Cursor_Ordering {
  /** ascending ordering of the cursor */
  Asc = 'ASC',
  /** descending ordering of the cursor */
  Desc = 'DESC',
}

export type Evidence_Integrations = {
  __typename?: 'evidence_integrations';
  config: Scalars['EvidenceIntegrationConfig']['output'];
};

export type Evidences = {
  __typename?: 'evidences';
  controls_aggregate_status: Scalars['EvidenceStatus']['output'];
};

/** columns and relationships of "storage.files" */
export type Files = {
  __typename?: 'files';
  bucketId: Scalars['String']['output'];
  createdAt: Scalars['timestamptz']['output'];
  etag?: Maybe<Scalars['String']['output']>;
  id: Scalars['uuid']['output'];
  isUploaded?: Maybe<Scalars['Boolean']['output']>;
  metadata?: Maybe<Scalars['jsonb']['output']>;
  mimeType?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  questionnaire_file?: Maybe<Questionnaires>;
  /** An array relationship */
  questionnaire_uploads: Array<Questionnaire_Uploads>;
  size?: Maybe<Scalars['Int']['output']>;
  updatedAt: Scalars['timestamptz']['output'];
  uploadedByUserId?: Maybe<Scalars['uuid']['output']>;
  /** An array relationship */
  vendor_questionnaire_form_uploads: Array<Vendor_Questionnaire_Form_Uploads>;
  /** An array relationship */
  vendor_questionnaire_uploads: Array<Vendor_Questionnaire_Uploads>;
};

/** columns and relationships of "storage.files" */
export type FilesMetadataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "storage.files" */
export type FilesQuestionnaire_UploadsArgs = {
  distinct_on?: InputMaybe<Array<Questionnaire_Uploads_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Questionnaire_Uploads_Order_By>>;
  where?: InputMaybe<Questionnaire_Uploads_Bool_Exp>;
};

/** columns and relationships of "storage.files" */
export type FilesVendor_Questionnaire_Form_UploadsArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Questionnaire_Form_Uploads_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Questionnaire_Form_Uploads_Order_By>>;
  where?: InputMaybe<Vendor_Questionnaire_Form_Uploads_Bool_Exp>;
};

/** columns and relationships of "storage.files" */
export type FilesVendor_Questionnaire_UploadsArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Questionnaire_Uploads_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Questionnaire_Uploads_Order_By>>;
  where?: InputMaybe<Vendor_Questionnaire_Uploads_Bool_Exp>;
};

/** Boolean expression to filter rows from the table "storage.files". All fields are combined with a logical 'AND'. */
export type Files_Bool_Exp = {
  _and?: InputMaybe<Array<Files_Bool_Exp>>;
  _not?: InputMaybe<Files_Bool_Exp>;
  _or?: InputMaybe<Array<Files_Bool_Exp>>;
  bucketId?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  etag?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  isUploaded?: InputMaybe<Boolean_Comparison_Exp>;
  metadata?: InputMaybe<Jsonb_Comparison_Exp>;
  mimeType?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  questionnaire_file?: InputMaybe<Questionnaires_Bool_Exp>;
  questionnaire_uploads?: InputMaybe<Questionnaire_Uploads_Bool_Exp>;
  size?: InputMaybe<Int_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  uploadedByUserId?: InputMaybe<Uuid_Comparison_Exp>;
  vendor_questionnaire_form_uploads?: InputMaybe<Vendor_Questionnaire_Form_Uploads_Bool_Exp>;
  vendor_questionnaire_uploads?: InputMaybe<Vendor_Questionnaire_Uploads_Bool_Exp>;
};

/** unique or primary key constraints on table "storage.files" */
export enum Files_Constraint {
  /** unique or primary key constraint on columns "id" */
  FilesPkey = 'files_pkey',
}

/** input type for inserting data into table "storage.files" */
export type Files_Insert_Input = {
  bucketId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  etag?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  isUploaded?: InputMaybe<Scalars['Boolean']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  mimeType?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  size?: InputMaybe<Scalars['Int']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  uploadedByUserId?: InputMaybe<Scalars['uuid']['input']>;
  vendor_questionnaire_form_uploads?: InputMaybe<Vendor_Questionnaire_Form_Uploads_Arr_Rel_Insert_Input>;
  vendor_questionnaire_uploads?: InputMaybe<Vendor_Questionnaire_Uploads_Arr_Rel_Insert_Input>;
};

/** response of any mutation on the table "storage.files" */
export type Files_Mutation_Response = {
  __typename?: 'files_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Files>;
};

/** input type for inserting object relation for remote table "storage.files" */
export type Files_Obj_Rel_Insert_Input = {
  data: Files_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Files_On_Conflict>;
};

/** on_conflict condition type for table "storage.files" */
export type Files_On_Conflict = {
  constraint: Files_Constraint;
  update_columns?: Array<Files_Update_Column>;
  where?: InputMaybe<Files_Bool_Exp>;
};

/** Ordering options when selecting data from "storage.files". */
export type Files_Order_By = {
  bucketId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  etag?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  isUploaded?: InputMaybe<Order_By>;
  metadata?: InputMaybe<Order_By>;
  mimeType?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  questionnaire_file?: InputMaybe<Questionnaires_Order_By>;
  questionnaire_uploads_aggregate?: InputMaybe<Questionnaire_Uploads_Aggregate_Order_By>;
  size?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  uploadedByUserId?: InputMaybe<Order_By>;
  vendor_questionnaire_form_uploads_aggregate?: InputMaybe<Vendor_Questionnaire_Form_Uploads_Aggregate_Order_By>;
  vendor_questionnaire_uploads_aggregate?: InputMaybe<Vendor_Questionnaire_Uploads_Aggregate_Order_By>;
};

/** select columns of table "storage.files" */
export enum Files_Select_Column {
  /** column name */
  BucketId = 'bucketId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Etag = 'etag',
  /** column name */
  Id = 'id',
  /** column name */
  IsUploaded = 'isUploaded',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  MimeType = 'mimeType',
  /** column name */
  Name = 'name',
  /** column name */
  Size = 'size',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UploadedByUserId = 'uploadedByUserId',
}

/** Streaming cursor of the table "files" */
export type Files_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Files_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Files_Stream_Cursor_Value_Input = {
  bucketId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  etag?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  isUploaded?: InputMaybe<Scalars['Boolean']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  mimeType?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  size?: InputMaybe<Scalars['Int']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  uploadedByUserId?: InputMaybe<Scalars['uuid']['input']>;
};

/** placeholder for update columns of table "storage.files" (current role has no relevant permissions) */
export enum Files_Update_Column {
  /** placeholder (do not use) */
  Placeholder = '_PLACEHOLDER',
}

export type Forms = {
  __typename?: 'forms';
  config?: Maybe<Scalars['FormConfig']['output']>;
};

export type Jsonb_Cast_Exp = {
  String?: InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type Jsonb_Comparison_Exp = {
  _cast?: InputMaybe<Jsonb_Cast_Exp>;
  /** is the column contained in the given json value */
  _contained_in?: InputMaybe<Scalars['jsonb']['input']>;
  /** does the column contain the given json value at the top level */
  _contains?: InputMaybe<Scalars['jsonb']['input']>;
  _eq?: InputMaybe<Scalars['jsonb']['input']>;
  _gt?: InputMaybe<Scalars['jsonb']['input']>;
  _gte?: InputMaybe<Scalars['jsonb']['input']>;
  /** does the string exist as a top-level key in the column */
  _has_key?: InputMaybe<Scalars['String']['input']>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: InputMaybe<Array<Scalars['String']['input']>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: InputMaybe<Array<Scalars['String']['input']>>;
  _in?: InputMaybe<Array<Scalars['jsonb']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['jsonb']['input']>;
  _lte?: InputMaybe<Scalars['jsonb']['input']>;
  _neq?: InputMaybe<Scalars['jsonb']['input']>;
  _nin?: InputMaybe<Array<Scalars['jsonb']['input']>>;
};

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  /** delete data from the table: "vendor_questionnaire_form_answers" */
  delete_vendor_questionnaire_form_answers?: Maybe<Vendor_Questionnaire_Form_Answers_Mutation_Response>;
  /** delete single row from the table: "vendor_questionnaire_form_answers" */
  delete_vendor_questionnaire_form_answers_by_pk?: Maybe<Vendor_Questionnaire_Form_Answers>;
  /** delete data from the table: "vendor_questionnaire_form_uploads" */
  delete_vendor_questionnaire_form_uploads?: Maybe<Vendor_Questionnaire_Form_Uploads_Mutation_Response>;
  /** delete single row from the table: "vendor_questionnaire_form_uploads" */
  delete_vendor_questionnaire_form_uploads_by_pk?: Maybe<Vendor_Questionnaire_Form_Uploads>;
  /** insert a single row into the table: "storage.files" */
  insertFile?: Maybe<Files>;
  /** insert data into the table: "storage.files" */
  insertFiles?: Maybe<Files_Mutation_Response>;
  /** insert data into the table: "vendor_questionnaire_form_answers" */
  insert_vendor_questionnaire_form_answers?: Maybe<Vendor_Questionnaire_Form_Answers_Mutation_Response>;
  /** insert a single row into the table: "vendor_questionnaire_form_answers" */
  insert_vendor_questionnaire_form_answers_one?: Maybe<Vendor_Questionnaire_Form_Answers>;
  /** insert data into the table: "vendor_questionnaire_form_uploads" */
  insert_vendor_questionnaire_form_uploads?: Maybe<Vendor_Questionnaire_Form_Uploads_Mutation_Response>;
  /** insert a single row into the table: "vendor_questionnaire_form_uploads" */
  insert_vendor_questionnaire_form_uploads_one?: Maybe<Vendor_Questionnaire_Form_Uploads>;
  /** insert data into the table: "vendor_questionnaire_uploads" */
  insert_vendor_questionnaire_uploads?: Maybe<Vendor_Questionnaire_Uploads_Mutation_Response>;
  /** insert a single row into the table: "vendor_questionnaire_uploads" */
  insert_vendor_questionnaire_uploads_one?: Maybe<Vendor_Questionnaire_Uploads>;
  /** update data of the table: "vendor_questionnaire_form_answers" */
  update_vendor_questionnaire_form_answers?: Maybe<Vendor_Questionnaire_Form_Answers_Mutation_Response>;
  /** update single row of the table: "vendor_questionnaire_form_answers" */
  update_vendor_questionnaire_form_answers_by_pk?: Maybe<Vendor_Questionnaire_Form_Answers>;
  /** update multiples rows of table: "vendor_questionnaire_form_answers" */
  update_vendor_questionnaire_form_answers_many?: Maybe<
    Array<Maybe<Vendor_Questionnaire_Form_Answers_Mutation_Response>>
  >;
  /** update data of the table: "vendor_questionnaire_form_uploads" */
  update_vendor_questionnaire_form_uploads?: Maybe<Vendor_Questionnaire_Form_Uploads_Mutation_Response>;
  /** update single row of the table: "vendor_questionnaire_form_uploads" */
  update_vendor_questionnaire_form_uploads_by_pk?: Maybe<Vendor_Questionnaire_Form_Uploads>;
  /** update multiples rows of table: "vendor_questionnaire_form_uploads" */
  update_vendor_questionnaire_form_uploads_many?: Maybe<
    Array<Maybe<Vendor_Questionnaire_Form_Uploads_Mutation_Response>>
  >;
  /** update data of the table: "vendor_questionnaires" */
  update_vendor_questionnaires?: Maybe<Vendor_Questionnaires_Mutation_Response>;
  /** update single row of the table: "vendor_questionnaires" */
  update_vendor_questionnaires_by_pk?: Maybe<Vendor_Questionnaires>;
  /** update multiples rows of table: "vendor_questionnaires" */
  update_vendor_questionnaires_many?: Maybe<Array<Maybe<Vendor_Questionnaires_Mutation_Response>>>;
};

/** mutation root */
export type Mutation_RootDelete_Vendor_Questionnaire_Form_AnswersArgs = {
  where: Vendor_Questionnaire_Form_Answers_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Vendor_Questionnaire_Form_Answers_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Vendor_Questionnaire_Form_UploadsArgs = {
  where: Vendor_Questionnaire_Form_Uploads_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Vendor_Questionnaire_Form_Uploads_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootInsertFileArgs = {
  object: Files_Insert_Input;
  on_conflict?: InputMaybe<Files_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsertFilesArgs = {
  objects: Array<Files_Insert_Input>;
  on_conflict?: InputMaybe<Files_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Vendor_Questionnaire_Form_AnswersArgs = {
  objects: Array<Vendor_Questionnaire_Form_Answers_Insert_Input>;
  on_conflict?: InputMaybe<Vendor_Questionnaire_Form_Answers_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Vendor_Questionnaire_Form_Answers_OneArgs = {
  object: Vendor_Questionnaire_Form_Answers_Insert_Input;
  on_conflict?: InputMaybe<Vendor_Questionnaire_Form_Answers_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Vendor_Questionnaire_Form_UploadsArgs = {
  objects: Array<Vendor_Questionnaire_Form_Uploads_Insert_Input>;
  on_conflict?: InputMaybe<Vendor_Questionnaire_Form_Uploads_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Vendor_Questionnaire_Form_Uploads_OneArgs = {
  object: Vendor_Questionnaire_Form_Uploads_Insert_Input;
  on_conflict?: InputMaybe<Vendor_Questionnaire_Form_Uploads_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Vendor_Questionnaire_UploadsArgs = {
  objects: Array<Vendor_Questionnaire_Uploads_Insert_Input>;
  on_conflict?: InputMaybe<Vendor_Questionnaire_Uploads_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Vendor_Questionnaire_Uploads_OneArgs = {
  object: Vendor_Questionnaire_Uploads_Insert_Input;
  on_conflict?: InputMaybe<Vendor_Questionnaire_Uploads_On_Conflict>;
};

/** mutation root */
export type Mutation_RootUpdate_Vendor_Questionnaire_Form_AnswersArgs = {
  _append?: InputMaybe<Vendor_Questionnaire_Form_Answers_Append_Input>;
  _delete_at_path?: InputMaybe<Vendor_Questionnaire_Form_Answers_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Vendor_Questionnaire_Form_Answers_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Vendor_Questionnaire_Form_Answers_Delete_Key_Input>;
  _prepend?: InputMaybe<Vendor_Questionnaire_Form_Answers_Prepend_Input>;
  _set?: InputMaybe<Vendor_Questionnaire_Form_Answers_Set_Input>;
  where: Vendor_Questionnaire_Form_Answers_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Vendor_Questionnaire_Form_Answers_By_PkArgs = {
  _append?: InputMaybe<Vendor_Questionnaire_Form_Answers_Append_Input>;
  _delete_at_path?: InputMaybe<Vendor_Questionnaire_Form_Answers_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Vendor_Questionnaire_Form_Answers_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Vendor_Questionnaire_Form_Answers_Delete_Key_Input>;
  _prepend?: InputMaybe<Vendor_Questionnaire_Form_Answers_Prepend_Input>;
  _set?: InputMaybe<Vendor_Questionnaire_Form_Answers_Set_Input>;
  pk_columns: Vendor_Questionnaire_Form_Answers_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Vendor_Questionnaire_Form_Answers_ManyArgs = {
  updates: Array<Vendor_Questionnaire_Form_Answers_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Vendor_Questionnaire_Form_UploadsArgs = {
  _set?: InputMaybe<Vendor_Questionnaire_Form_Uploads_Set_Input>;
  where: Vendor_Questionnaire_Form_Uploads_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Vendor_Questionnaire_Form_Uploads_By_PkArgs = {
  _set?: InputMaybe<Vendor_Questionnaire_Form_Uploads_Set_Input>;
  pk_columns: Vendor_Questionnaire_Form_Uploads_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Vendor_Questionnaire_Form_Uploads_ManyArgs = {
  updates: Array<Vendor_Questionnaire_Form_Uploads_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Vendor_QuestionnairesArgs = {
  _set?: InputMaybe<Vendor_Questionnaires_Set_Input>;
  where: Vendor_Questionnaires_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Vendor_Questionnaires_By_PkArgs = {
  _set?: InputMaybe<Vendor_Questionnaires_Set_Input>;
  pk_columns: Vendor_Questionnaires_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Vendor_Questionnaires_ManyArgs = {
  updates: Array<Vendor_Questionnaires_Updates>;
};

export type Notifications = {
  __typename?: 'notifications';
  params: Scalars['NotificationParams']['output'];
};

export type Notifications_Insert_Input = {
  __typename?: 'notifications_insert_input';
  params: Scalars['NotificationParams']['output'];
};

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last',
}

export type Query_Root = {
  __typename?: 'query_root';
  /** fetch data from the table: "storage.files" using primary key columns */
  file?: Maybe<Files>;
  /** fetch data from the table: "storage.files" */
  files: Array<Files>;
  /** An array relationship */
  questionnaire_uploads: Array<Questionnaire_Uploads>;
  /** fetch data from the table: "questionnaire_uploads" using primary key columns */
  questionnaire_uploads_by_pk?: Maybe<Questionnaire_Uploads>;
  /** An array relationship */
  questionnaires: Array<Questionnaires>;
  /** fetch data from the table: "questionnaires" using primary key columns */
  questionnaires_by_pk?: Maybe<Questionnaires>;
  /** fetch data from the table: "auth.users" using primary key columns */
  user?: Maybe<Users>;
  /** fetch data from the table: "auth.users" */
  users: Array<Users>;
  /** fetch data from the table: "vendor_questionnaire_form_answer_statuses" */
  vendor_questionnaire_form_answer_statuses: Array<Vendor_Questionnaire_Form_Answer_Statuses>;
  /** fetch data from the table: "vendor_questionnaire_form_answer_statuses" using primary key columns */
  vendor_questionnaire_form_answer_statuses_by_pk?: Maybe<Vendor_Questionnaire_Form_Answer_Statuses>;
  /** An array relationship */
  vendor_questionnaire_form_answers: Array<Vendor_Questionnaire_Form_Answers>;
  /** fetch data from the table: "vendor_questionnaire_form_answers" using primary key columns */
  vendor_questionnaire_form_answers_by_pk?: Maybe<Vendor_Questionnaire_Form_Answers>;
  /** An array relationship */
  vendor_questionnaire_form_uploads: Array<Vendor_Questionnaire_Form_Uploads>;
  /** fetch data from the table: "vendor_questionnaire_form_uploads" using primary key columns */
  vendor_questionnaire_form_uploads_by_pk?: Maybe<Vendor_Questionnaire_Form_Uploads>;
  /** fetch data from the table: "vendor_questionnaire_forms" */
  vendor_questionnaire_forms: Array<Vendor_Questionnaire_Forms>;
  /** fetch data from the table: "vendor_questionnaire_forms" using primary key columns */
  vendor_questionnaire_forms_by_pk?: Maybe<Vendor_Questionnaire_Forms>;
  /** fetch data from the table: "vendor_questionnaire_statuses" */
  vendor_questionnaire_statuses: Array<Vendor_Questionnaire_Statuses>;
  /** fetch data from the table: "vendor_questionnaire_statuses" using primary key columns */
  vendor_questionnaire_statuses_by_pk?: Maybe<Vendor_Questionnaire_Statuses>;
  /** An array relationship */
  vendor_questionnaire_uploads: Array<Vendor_Questionnaire_Uploads>;
  /** fetch data from the table: "vendor_questionnaire_uploads" using primary key columns */
  vendor_questionnaire_uploads_by_pk?: Maybe<Vendor_Questionnaire_Uploads>;
  /** An array relationship */
  vendor_questionnaires: Array<Vendor_Questionnaires>;
  /** fetch data from the table: "vendor_questionnaires" using primary key columns */
  vendor_questionnaires_by_pk?: Maybe<Vendor_Questionnaires>;
};

export type Query_RootFileArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootFilesArgs = {
  distinct_on?: InputMaybe<Array<Files_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Files_Order_By>>;
  where?: InputMaybe<Files_Bool_Exp>;
};

export type Query_RootQuestionnaire_UploadsArgs = {
  distinct_on?: InputMaybe<Array<Questionnaire_Uploads_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Questionnaire_Uploads_Order_By>>;
  where?: InputMaybe<Questionnaire_Uploads_Bool_Exp>;
};

export type Query_RootQuestionnaire_Uploads_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootQuestionnairesArgs = {
  distinct_on?: InputMaybe<Array<Questionnaires_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Questionnaires_Order_By>>;
  where?: InputMaybe<Questionnaires_Bool_Exp>;
};

export type Query_RootQuestionnaires_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootUserArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootUsersArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};

export type Query_RootVendor_Questionnaire_Form_Answer_StatusesArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Questionnaire_Form_Answer_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Questionnaire_Form_Answer_Statuses_Order_By>>;
  where?: InputMaybe<Vendor_Questionnaire_Form_Answer_Statuses_Bool_Exp>;
};

export type Query_RootVendor_Questionnaire_Form_Answer_Statuses_By_PkArgs = {
  value: Scalars['String']['input'];
};

export type Query_RootVendor_Questionnaire_Form_AnswersArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Questionnaire_Form_Answers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Questionnaire_Form_Answers_Order_By>>;
  where?: InputMaybe<Vendor_Questionnaire_Form_Answers_Bool_Exp>;
};

export type Query_RootVendor_Questionnaire_Form_Answers_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootVendor_Questionnaire_Form_UploadsArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Questionnaire_Form_Uploads_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Questionnaire_Form_Uploads_Order_By>>;
  where?: InputMaybe<Vendor_Questionnaire_Form_Uploads_Bool_Exp>;
};

export type Query_RootVendor_Questionnaire_Form_Uploads_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootVendor_Questionnaire_FormsArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Questionnaire_Forms_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Questionnaire_Forms_Order_By>>;
  where?: InputMaybe<Vendor_Questionnaire_Forms_Bool_Exp>;
};

export type Query_RootVendor_Questionnaire_Forms_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootVendor_Questionnaire_StatusesArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Questionnaire_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Questionnaire_Statuses_Order_By>>;
  where?: InputMaybe<Vendor_Questionnaire_Statuses_Bool_Exp>;
};

export type Query_RootVendor_Questionnaire_Statuses_By_PkArgs = {
  value: Scalars['String']['input'];
};

export type Query_RootVendor_Questionnaire_UploadsArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Questionnaire_Uploads_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Questionnaire_Uploads_Order_By>>;
  where?: InputMaybe<Vendor_Questionnaire_Uploads_Bool_Exp>;
};

export type Query_RootVendor_Questionnaire_Uploads_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootVendor_QuestionnairesArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Questionnaires_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Questionnaires_Order_By>>;
  where?: InputMaybe<Vendor_Questionnaires_Bool_Exp>;
};

export type Query_RootVendor_Questionnaires_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** File uploads for questionnaires */
export type Questionnaire_Uploads = {
  __typename?: 'questionnaire_uploads';
  /** An object relationship */
  file: Files;
  file_id: Scalars['uuid']['output'];
  id: Scalars['uuid']['output'];
  /** An object relationship */
  questionnaire: Questionnaires;
  questionnaire_id: Scalars['uuid']['output'];
};

/** order by aggregate values of table "questionnaire_uploads" */
export type Questionnaire_Uploads_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Questionnaire_Uploads_Max_Order_By>;
  min?: InputMaybe<Questionnaire_Uploads_Min_Order_By>;
};

/** Boolean expression to filter rows from the table "questionnaire_uploads". All fields are combined with a logical 'AND'. */
export type Questionnaire_Uploads_Bool_Exp = {
  _and?: InputMaybe<Array<Questionnaire_Uploads_Bool_Exp>>;
  _not?: InputMaybe<Questionnaire_Uploads_Bool_Exp>;
  _or?: InputMaybe<Array<Questionnaire_Uploads_Bool_Exp>>;
  file?: InputMaybe<Files_Bool_Exp>;
  file_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  questionnaire?: InputMaybe<Questionnaires_Bool_Exp>;
  questionnaire_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** order by max() on columns of table "questionnaire_uploads" */
export type Questionnaire_Uploads_Max_Order_By = {
  file_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  questionnaire_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "questionnaire_uploads" */
export type Questionnaire_Uploads_Min_Order_By = {
  file_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  questionnaire_id?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "questionnaire_uploads". */
export type Questionnaire_Uploads_Order_By = {
  file?: InputMaybe<Files_Order_By>;
  file_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  questionnaire?: InputMaybe<Questionnaires_Order_By>;
  questionnaire_id?: InputMaybe<Order_By>;
};

/** select columns of table "questionnaire_uploads" */
export enum Questionnaire_Uploads_Select_Column {
  /** column name */
  FileId = 'file_id',
  /** column name */
  Id = 'id',
  /** column name */
  QuestionnaireId = 'questionnaire_id',
}

/** Streaming cursor of the table "questionnaire_uploads" */
export type Questionnaire_Uploads_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Questionnaire_Uploads_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Questionnaire_Uploads_Stream_Cursor_Value_Input = {
  file_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  questionnaire_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** columns and relationships of "questionnaires" */
export type Questionnaires = {
  __typename?: 'questionnaires';
  created_at: Scalars['timestamptz']['output'];
  description?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  file?: Maybe<Files>;
  file_id?: Maybe<Scalars['uuid']['output']>;
  form_id?: Maybe<Scalars['uuid']['output']>;
  id: Scalars['uuid']['output'];
  name?: Maybe<Scalars['String']['output']>;
  organization_id: Scalars['uuid']['output'];
  /** An object relationship */
  owner?: Maybe<Users>;
  owner_id?: Maybe<Scalars['uuid']['output']>;
  /** An array relationship */
  questionnaire_uploads: Array<Questionnaire_Uploads>;
  updated_at: Scalars['timestamptz']['output'];
  /** An array relationship */
  vendor_questionnaires: Array<Vendor_Questionnaires>;
};

/** columns and relationships of "questionnaires" */
export type QuestionnairesQuestionnaire_UploadsArgs = {
  distinct_on?: InputMaybe<Array<Questionnaire_Uploads_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Questionnaire_Uploads_Order_By>>;
  where?: InputMaybe<Questionnaire_Uploads_Bool_Exp>;
};

/** columns and relationships of "questionnaires" */
export type QuestionnairesVendor_QuestionnairesArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Questionnaires_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Questionnaires_Order_By>>;
  where?: InputMaybe<Vendor_Questionnaires_Bool_Exp>;
};

/** order by aggregate values of table "questionnaires" */
export type Questionnaires_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Questionnaires_Max_Order_By>;
  min?: InputMaybe<Questionnaires_Min_Order_By>;
};

/** Boolean expression to filter rows from the table "questionnaires". All fields are combined with a logical 'AND'. */
export type Questionnaires_Bool_Exp = {
  _and?: InputMaybe<Array<Questionnaires_Bool_Exp>>;
  _not?: InputMaybe<Questionnaires_Bool_Exp>;
  _or?: InputMaybe<Array<Questionnaires_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  file?: InputMaybe<Files_Bool_Exp>;
  file_id?: InputMaybe<Uuid_Comparison_Exp>;
  form_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  organization_id?: InputMaybe<Uuid_Comparison_Exp>;
  owner?: InputMaybe<Users_Bool_Exp>;
  owner_id?: InputMaybe<Uuid_Comparison_Exp>;
  questionnaire_uploads?: InputMaybe<Questionnaire_Uploads_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  vendor_questionnaires?: InputMaybe<Vendor_Questionnaires_Bool_Exp>;
};

/** order by max() on columns of table "questionnaires" */
export type Questionnaires_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  file_id?: InputMaybe<Order_By>;
  form_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  owner_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "questionnaires" */
export type Questionnaires_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  file_id?: InputMaybe<Order_By>;
  form_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  owner_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "questionnaires". */
export type Questionnaires_Order_By = {
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  file?: InputMaybe<Files_Order_By>;
  file_id?: InputMaybe<Order_By>;
  form_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  owner?: InputMaybe<Users_Order_By>;
  owner_id?: InputMaybe<Order_By>;
  questionnaire_uploads_aggregate?: InputMaybe<Questionnaire_Uploads_Aggregate_Order_By>;
  updated_at?: InputMaybe<Order_By>;
  vendor_questionnaires_aggregate?: InputMaybe<Vendor_Questionnaires_Aggregate_Order_By>;
};

/** select columns of table "questionnaires" */
export enum Questionnaires_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  FileId = 'file_id',
  /** column name */
  FormId = 'form_id',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  OwnerId = 'owner_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** Streaming cursor of the table "questionnaires" */
export type Questionnaires_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Questionnaires_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Questionnaires_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  file_id?: InputMaybe<Scalars['uuid']['input']>;
  form_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['uuid']['input']>;
  owner_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

export type Reports = {
  __typename?: 'reports';
  filters?: Maybe<Scalars['ColumnFiltersState']['output']>;
  sorting?: Maybe<Scalars['SortingState']['output']>;
};

export type Risks = {
  __typename?: 'risks';
  assessment_status: Scalars['RiskAssessmentStatus']['output'];
};

export type Subscription_Root = {
  __typename?: 'subscription_root';
  /** fetch data from the table: "storage.files" using primary key columns */
  file?: Maybe<Files>;
  /** fetch data from the table: "storage.files" */
  files: Array<Files>;
  /** fetch data from the table in a streaming manner: "storage.files" */
  files_stream: Array<Files>;
  /** An array relationship */
  questionnaire_uploads: Array<Questionnaire_Uploads>;
  /** fetch data from the table: "questionnaire_uploads" using primary key columns */
  questionnaire_uploads_by_pk?: Maybe<Questionnaire_Uploads>;
  /** fetch data from the table in a streaming manner: "questionnaire_uploads" */
  questionnaire_uploads_stream: Array<Questionnaire_Uploads>;
  /** An array relationship */
  questionnaires: Array<Questionnaires>;
  /** fetch data from the table: "questionnaires" using primary key columns */
  questionnaires_by_pk?: Maybe<Questionnaires>;
  /** fetch data from the table in a streaming manner: "questionnaires" */
  questionnaires_stream: Array<Questionnaires>;
  /** fetch data from the table: "auth.users" using primary key columns */
  user?: Maybe<Users>;
  /** fetch data from the table: "auth.users" */
  users: Array<Users>;
  /** fetch data from the table in a streaming manner: "auth.users" */
  users_stream: Array<Users>;
  /** fetch data from the table: "vendor_questionnaire_form_answer_statuses" */
  vendor_questionnaire_form_answer_statuses: Array<Vendor_Questionnaire_Form_Answer_Statuses>;
  /** fetch data from the table: "vendor_questionnaire_form_answer_statuses" using primary key columns */
  vendor_questionnaire_form_answer_statuses_by_pk?: Maybe<Vendor_Questionnaire_Form_Answer_Statuses>;
  /** fetch data from the table in a streaming manner: "vendor_questionnaire_form_answer_statuses" */
  vendor_questionnaire_form_answer_statuses_stream: Array<Vendor_Questionnaire_Form_Answer_Statuses>;
  /** An array relationship */
  vendor_questionnaire_form_answers: Array<Vendor_Questionnaire_Form_Answers>;
  /** fetch data from the table: "vendor_questionnaire_form_answers" using primary key columns */
  vendor_questionnaire_form_answers_by_pk?: Maybe<Vendor_Questionnaire_Form_Answers>;
  /** fetch data from the table in a streaming manner: "vendor_questionnaire_form_answers" */
  vendor_questionnaire_form_answers_stream: Array<Vendor_Questionnaire_Form_Answers>;
  /** An array relationship */
  vendor_questionnaire_form_uploads: Array<Vendor_Questionnaire_Form_Uploads>;
  /** fetch data from the table: "vendor_questionnaire_form_uploads" using primary key columns */
  vendor_questionnaire_form_uploads_by_pk?: Maybe<Vendor_Questionnaire_Form_Uploads>;
  /** fetch data from the table in a streaming manner: "vendor_questionnaire_form_uploads" */
  vendor_questionnaire_form_uploads_stream: Array<Vendor_Questionnaire_Form_Uploads>;
  /** fetch data from the table: "vendor_questionnaire_forms" */
  vendor_questionnaire_forms: Array<Vendor_Questionnaire_Forms>;
  /** fetch data from the table: "vendor_questionnaire_forms" using primary key columns */
  vendor_questionnaire_forms_by_pk?: Maybe<Vendor_Questionnaire_Forms>;
  /** fetch data from the table in a streaming manner: "vendor_questionnaire_forms" */
  vendor_questionnaire_forms_stream: Array<Vendor_Questionnaire_Forms>;
  /** fetch data from the table: "vendor_questionnaire_statuses" */
  vendor_questionnaire_statuses: Array<Vendor_Questionnaire_Statuses>;
  /** fetch data from the table: "vendor_questionnaire_statuses" using primary key columns */
  vendor_questionnaire_statuses_by_pk?: Maybe<Vendor_Questionnaire_Statuses>;
  /** fetch data from the table in a streaming manner: "vendor_questionnaire_statuses" */
  vendor_questionnaire_statuses_stream: Array<Vendor_Questionnaire_Statuses>;
  /** An array relationship */
  vendor_questionnaire_uploads: Array<Vendor_Questionnaire_Uploads>;
  /** fetch data from the table: "vendor_questionnaire_uploads" using primary key columns */
  vendor_questionnaire_uploads_by_pk?: Maybe<Vendor_Questionnaire_Uploads>;
  /** fetch data from the table in a streaming manner: "vendor_questionnaire_uploads" */
  vendor_questionnaire_uploads_stream: Array<Vendor_Questionnaire_Uploads>;
  /** An array relationship */
  vendor_questionnaires: Array<Vendor_Questionnaires>;
  /** fetch data from the table: "vendor_questionnaires" using primary key columns */
  vendor_questionnaires_by_pk?: Maybe<Vendor_Questionnaires>;
  /** fetch data from the table in a streaming manner: "vendor_questionnaires" */
  vendor_questionnaires_stream: Array<Vendor_Questionnaires>;
};

export type Subscription_RootFileArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootFilesArgs = {
  distinct_on?: InputMaybe<Array<Files_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Files_Order_By>>;
  where?: InputMaybe<Files_Bool_Exp>;
};

export type Subscription_RootFiles_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Files_Stream_Cursor_Input>>;
  where?: InputMaybe<Files_Bool_Exp>;
};

export type Subscription_RootQuestionnaire_UploadsArgs = {
  distinct_on?: InputMaybe<Array<Questionnaire_Uploads_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Questionnaire_Uploads_Order_By>>;
  where?: InputMaybe<Questionnaire_Uploads_Bool_Exp>;
};

export type Subscription_RootQuestionnaire_Uploads_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootQuestionnaire_Uploads_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Questionnaire_Uploads_Stream_Cursor_Input>>;
  where?: InputMaybe<Questionnaire_Uploads_Bool_Exp>;
};

export type Subscription_RootQuestionnairesArgs = {
  distinct_on?: InputMaybe<Array<Questionnaires_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Questionnaires_Order_By>>;
  where?: InputMaybe<Questionnaires_Bool_Exp>;
};

export type Subscription_RootQuestionnaires_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootQuestionnaires_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Questionnaires_Stream_Cursor_Input>>;
  where?: InputMaybe<Questionnaires_Bool_Exp>;
};

export type Subscription_RootUserArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootUsersArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};

export type Subscription_RootUsers_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Users_Stream_Cursor_Input>>;
  where?: InputMaybe<Users_Bool_Exp>;
};

export type Subscription_RootVendor_Questionnaire_Form_Answer_StatusesArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Questionnaire_Form_Answer_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Questionnaire_Form_Answer_Statuses_Order_By>>;
  where?: InputMaybe<Vendor_Questionnaire_Form_Answer_Statuses_Bool_Exp>;
};

export type Subscription_RootVendor_Questionnaire_Form_Answer_Statuses_By_PkArgs = {
  value: Scalars['String']['input'];
};

export type Subscription_RootVendor_Questionnaire_Form_Answer_Statuses_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Vendor_Questionnaire_Form_Answer_Statuses_Stream_Cursor_Input>>;
  where?: InputMaybe<Vendor_Questionnaire_Form_Answer_Statuses_Bool_Exp>;
};

export type Subscription_RootVendor_Questionnaire_Form_AnswersArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Questionnaire_Form_Answers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Questionnaire_Form_Answers_Order_By>>;
  where?: InputMaybe<Vendor_Questionnaire_Form_Answers_Bool_Exp>;
};

export type Subscription_RootVendor_Questionnaire_Form_Answers_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootVendor_Questionnaire_Form_Answers_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Vendor_Questionnaire_Form_Answers_Stream_Cursor_Input>>;
  where?: InputMaybe<Vendor_Questionnaire_Form_Answers_Bool_Exp>;
};

export type Subscription_RootVendor_Questionnaire_Form_UploadsArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Questionnaire_Form_Uploads_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Questionnaire_Form_Uploads_Order_By>>;
  where?: InputMaybe<Vendor_Questionnaire_Form_Uploads_Bool_Exp>;
};

export type Subscription_RootVendor_Questionnaire_Form_Uploads_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootVendor_Questionnaire_Form_Uploads_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Vendor_Questionnaire_Form_Uploads_Stream_Cursor_Input>>;
  where?: InputMaybe<Vendor_Questionnaire_Form_Uploads_Bool_Exp>;
};

export type Subscription_RootVendor_Questionnaire_FormsArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Questionnaire_Forms_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Questionnaire_Forms_Order_By>>;
  where?: InputMaybe<Vendor_Questionnaire_Forms_Bool_Exp>;
};

export type Subscription_RootVendor_Questionnaire_Forms_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootVendor_Questionnaire_Forms_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Vendor_Questionnaire_Forms_Stream_Cursor_Input>>;
  where?: InputMaybe<Vendor_Questionnaire_Forms_Bool_Exp>;
};

export type Subscription_RootVendor_Questionnaire_StatusesArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Questionnaire_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Questionnaire_Statuses_Order_By>>;
  where?: InputMaybe<Vendor_Questionnaire_Statuses_Bool_Exp>;
};

export type Subscription_RootVendor_Questionnaire_Statuses_By_PkArgs = {
  value: Scalars['String']['input'];
};

export type Subscription_RootVendor_Questionnaire_Statuses_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Vendor_Questionnaire_Statuses_Stream_Cursor_Input>>;
  where?: InputMaybe<Vendor_Questionnaire_Statuses_Bool_Exp>;
};

export type Subscription_RootVendor_Questionnaire_UploadsArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Questionnaire_Uploads_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Questionnaire_Uploads_Order_By>>;
  where?: InputMaybe<Vendor_Questionnaire_Uploads_Bool_Exp>;
};

export type Subscription_RootVendor_Questionnaire_Uploads_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootVendor_Questionnaire_Uploads_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Vendor_Questionnaire_Uploads_Stream_Cursor_Input>>;
  where?: InputMaybe<Vendor_Questionnaire_Uploads_Bool_Exp>;
};

export type Subscription_RootVendor_QuestionnairesArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Questionnaires_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Questionnaires_Order_By>>;
  where?: InputMaybe<Vendor_Questionnaires_Bool_Exp>;
};

export type Subscription_RootVendor_Questionnaires_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootVendor_Questionnaires_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Vendor_Questionnaires_Stream_Cursor_Input>>;
  where?: InputMaybe<Vendor_Questionnaires_Bool_Exp>;
};

export type Tasks = {
  __typename?: 'tasks';
  status: Scalars['TaskStatus']['output'];
};

export type Tasks_Insert_Input = {
  __typename?: 'tasks_insert_input';
  organization_id: Scalars['uuid']['output'];
};

export type Template_Controls = {
  __typename?: 'template_controls';
  tags: Scalars['ControlTags']['output'];
};

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamptz']['input']>;
  _gt?: InputMaybe<Scalars['timestamptz']['input']>;
  _gte?: InputMaybe<Scalars['timestamptz']['input']>;
  _in?: InputMaybe<Array<Scalars['timestamptz']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['timestamptz']['input']>;
  _lte?: InputMaybe<Scalars['timestamptz']['input']>;
  _neq?: InputMaybe<Scalars['timestamptz']['input']>;
  _nin?: InputMaybe<Array<Scalars['timestamptz']['input']>>;
};

/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type Users = {
  __typename?: 'users';
  avatarUrl: Scalars['String']['output'];
  displayName: Scalars['String']['output'];
  email?: Maybe<Scalars['citext']['output']>;
  id: Scalars['uuid']['output'];
  metadata: Scalars['UserMetaData']['output'];
  /** An array relationship */
  questionnaires: Array<Questionnaires>;
  /** An array relationship */
  vendor_questionnaires_sent_by: Array<Vendor_Questionnaires>;
  /** An array relationship */
  vendor_questionnaires_sent_to: Array<Vendor_Questionnaires>;
};

/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type UsersQuestionnairesArgs = {
  distinct_on?: InputMaybe<Array<Questionnaires_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Questionnaires_Order_By>>;
  where?: InputMaybe<Questionnaires_Bool_Exp>;
};

/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type UsersVendor_Questionnaires_Sent_ByArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Questionnaires_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Questionnaires_Order_By>>;
  where?: InputMaybe<Vendor_Questionnaires_Bool_Exp>;
};

/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type UsersVendor_Questionnaires_Sent_ToArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Questionnaires_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Questionnaires_Order_By>>;
  where?: InputMaybe<Vendor_Questionnaires_Bool_Exp>;
};

export type Users_Append_Input = {
  __typename?: 'users_append_input';
  metadata?: Maybe<Scalars['UserMetaData']['output']>;
};

/** Boolean expression to filter rows from the table "auth.users". All fields are combined with a logical 'AND'. */
export type Users_Bool_Exp = {
  _and?: InputMaybe<Array<Users_Bool_Exp>>;
  _not?: InputMaybe<Users_Bool_Exp>;
  _or?: InputMaybe<Array<Users_Bool_Exp>>;
  avatarUrl?: InputMaybe<String_Comparison_Exp>;
  displayName?: InputMaybe<String_Comparison_Exp>;
  email?: InputMaybe<Citext_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  questionnaires?: InputMaybe<Questionnaires_Bool_Exp>;
  vendor_questionnaires_sent_by?: InputMaybe<Vendor_Questionnaires_Bool_Exp>;
  vendor_questionnaires_sent_to?: InputMaybe<Vendor_Questionnaires_Bool_Exp>;
};

/** Ordering options when selecting data from "auth.users". */
export type Users_Order_By = {
  avatarUrl?: InputMaybe<Order_By>;
  displayName?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  questionnaires_aggregate?: InputMaybe<Questionnaires_Aggregate_Order_By>;
  vendor_questionnaires_sent_by_aggregate?: InputMaybe<Vendor_Questionnaires_Aggregate_Order_By>;
  vendor_questionnaires_sent_to_aggregate?: InputMaybe<Vendor_Questionnaires_Aggregate_Order_By>;
};

/** select columns of table "auth.users" */
export enum Users_Select_Column {
  /** column name */
  AvatarUrl = 'avatarUrl',
  /** column name */
  DisplayName = 'displayName',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
}

/** Streaming cursor of the table "users" */
export type Users_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Users_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Users_Stream_Cursor_Value_Input = {
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['citext']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
};

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['uuid']['input']>;
  _gt?: InputMaybe<Scalars['uuid']['input']>;
  _gte?: InputMaybe<Scalars['uuid']['input']>;
  _in?: InputMaybe<Array<Scalars['uuid']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['uuid']['input']>;
  _lte?: InputMaybe<Scalars['uuid']['input']>;
  _neq?: InputMaybe<Scalars['uuid']['input']>;
  _nin?: InputMaybe<Array<Scalars['uuid']['input']>>;
};

/** columns and relationships of "vendor_questionnaire_form_answer_statuses" */
export type Vendor_Questionnaire_Form_Answer_Statuses = {
  __typename?: 'vendor_questionnaire_form_answer_statuses';
  comment?: Maybe<Scalars['String']['output']>;
  value: Scalars['String']['output'];
  /** An array relationship */
  vendor_questionnaire_form_answers: Array<Vendor_Questionnaire_Form_Answers>;
};

/** columns and relationships of "vendor_questionnaire_form_answer_statuses" */
export type Vendor_Questionnaire_Form_Answer_StatusesVendor_Questionnaire_Form_AnswersArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Questionnaire_Form_Answers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Questionnaire_Form_Answers_Order_By>>;
  where?: InputMaybe<Vendor_Questionnaire_Form_Answers_Bool_Exp>;
};

/** Boolean expression to filter rows from the table "vendor_questionnaire_form_answer_statuses". All fields are combined with a logical 'AND'. */
export type Vendor_Questionnaire_Form_Answer_Statuses_Bool_Exp = {
  _and?: InputMaybe<Array<Vendor_Questionnaire_Form_Answer_Statuses_Bool_Exp>>;
  _not?: InputMaybe<Vendor_Questionnaire_Form_Answer_Statuses_Bool_Exp>;
  _or?: InputMaybe<Array<Vendor_Questionnaire_Form_Answer_Statuses_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
  vendor_questionnaire_form_answers?: InputMaybe<Vendor_Questionnaire_Form_Answers_Bool_Exp>;
};

export enum Vendor_Questionnaire_Form_Answer_Statuses_Enum {
  /** An answer which has been marked as completed */
  Completed = 'completed',
  /** An answer which may have data but not marked as completed yet */
  Pending = 'pending',
}

/** Boolean expression to compare columns of type "vendor_questionnaire_form_answer_statuses_enum". All fields are combined with logical 'AND'. */
export type Vendor_Questionnaire_Form_Answer_Statuses_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Vendor_Questionnaire_Form_Answer_Statuses_Enum>;
  _in?: InputMaybe<Array<Vendor_Questionnaire_Form_Answer_Statuses_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Vendor_Questionnaire_Form_Answer_Statuses_Enum>;
  _nin?: InputMaybe<Array<Vendor_Questionnaire_Form_Answer_Statuses_Enum>>;
};

/** Ordering options when selecting data from "vendor_questionnaire_form_answer_statuses". */
export type Vendor_Questionnaire_Form_Answer_Statuses_Order_By = {
  comment?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
  vendor_questionnaire_form_answers_aggregate?: InputMaybe<Vendor_Questionnaire_Form_Answers_Aggregate_Order_By>;
};

/** select columns of table "vendor_questionnaire_form_answer_statuses" */
export enum Vendor_Questionnaire_Form_Answer_Statuses_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value',
}

/** Streaming cursor of the table "vendor_questionnaire_form_answer_statuses" */
export type Vendor_Questionnaire_Form_Answer_Statuses_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Vendor_Questionnaire_Form_Answer_Statuses_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Vendor_Questionnaire_Form_Answer_Statuses_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "vendor_questionnaire_form_answers" */
export type Vendor_Questionnaire_Form_Answers = {
  __typename?: 'vendor_questionnaire_form_answers';
  comment?: Maybe<Scalars['String']['output']>;
  created_at: Scalars['timestamptz']['output'];
  field_name: Scalars['String']['output'];
  /** An object relationship */
  form: Vendor_Questionnaire_Forms;
  /** An object relationship */
  form_answer_status: Vendor_Questionnaire_Form_Answer_Statuses;
  /** An array relationship */
  form_uploads: Array<Vendor_Questionnaire_Form_Uploads>;
  id: Scalars['uuid']['output'];
  status: Vendor_Questionnaire_Form_Answer_Statuses_Enum;
  updated_at: Scalars['timestamptz']['output'];
  value?: Maybe<Scalars['VendorQuestionnaireFormAnswerValue']['output']>;
  vendor_questionnaire_form_id: Scalars['uuid']['output'];
};

/** columns and relationships of "vendor_questionnaire_form_answers" */
export type Vendor_Questionnaire_Form_AnswersForm_UploadsArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Questionnaire_Form_Uploads_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Questionnaire_Form_Uploads_Order_By>>;
  where?: InputMaybe<Vendor_Questionnaire_Form_Uploads_Bool_Exp>;
};

/** columns and relationships of "vendor_questionnaire_form_answers" */
export type Vendor_Questionnaire_Form_AnswersValueArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** order by aggregate values of table "vendor_questionnaire_form_answers" */
export type Vendor_Questionnaire_Form_Answers_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Vendor_Questionnaire_Form_Answers_Max_Order_By>;
  min?: InputMaybe<Vendor_Questionnaire_Form_Answers_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Vendor_Questionnaire_Form_Answers_Append_Input = {
  value?: InputMaybe<Scalars['jsonb']['input']>;
};

/** Boolean expression to filter rows from the table "vendor_questionnaire_form_answers". All fields are combined with a logical 'AND'. */
export type Vendor_Questionnaire_Form_Answers_Bool_Exp = {
  _and?: InputMaybe<Array<Vendor_Questionnaire_Form_Answers_Bool_Exp>>;
  _not?: InputMaybe<Vendor_Questionnaire_Form_Answers_Bool_Exp>;
  _or?: InputMaybe<Array<Vendor_Questionnaire_Form_Answers_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  field_name?: InputMaybe<String_Comparison_Exp>;
  form?: InputMaybe<Vendor_Questionnaire_Forms_Bool_Exp>;
  form_answer_status?: InputMaybe<Vendor_Questionnaire_Form_Answer_Statuses_Bool_Exp>;
  form_uploads?: InputMaybe<Vendor_Questionnaire_Form_Uploads_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  status?: InputMaybe<Vendor_Questionnaire_Form_Answer_Statuses_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  value?: InputMaybe<Jsonb_Comparison_Exp>;
  vendor_questionnaire_form_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "vendor_questionnaire_form_answers" */
export enum Vendor_Questionnaire_Form_Answers_Constraint {
  /** unique or primary key constraint on columns "field_name", "vendor_questionnaire_form_id" */
  VendorQuestionnaireFormAnswersFieldNameVendorQuestionnai = 'vendor_questionnaire_form_answers_field_name_vendor_questionnai',
  /** unique or primary key constraint on columns "id" */
  VendorQuestionnaireFormAnswersPkey = 'vendor_questionnaire_form_answers_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Vendor_Questionnaire_Form_Answers_Delete_At_Path_Input = {
  value?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Vendor_Questionnaire_Form_Answers_Delete_Elem_Input = {
  value?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Vendor_Questionnaire_Form_Answers_Delete_Key_Input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "vendor_questionnaire_form_answers" */
export type Vendor_Questionnaire_Form_Answers_Insert_Input = {
  comment?: InputMaybe<Scalars['String']['input']>;
  field_name?: InputMaybe<Scalars['String']['input']>;
  form_uploads?: InputMaybe<Vendor_Questionnaire_Form_Uploads_Arr_Rel_Insert_Input>;
  status?: InputMaybe<Vendor_Questionnaire_Form_Answer_Statuses_Enum>;
  value?: InputMaybe<Scalars['jsonb']['input']>;
  vendor_questionnaire_form_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** order by max() on columns of table "vendor_questionnaire_form_answers" */
export type Vendor_Questionnaire_Form_Answers_Max_Order_By = {
  comment?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  field_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  vendor_questionnaire_form_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "vendor_questionnaire_form_answers" */
export type Vendor_Questionnaire_Form_Answers_Min_Order_By = {
  comment?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  field_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  vendor_questionnaire_form_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "vendor_questionnaire_form_answers" */
export type Vendor_Questionnaire_Form_Answers_Mutation_Response = {
  __typename?: 'vendor_questionnaire_form_answers_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Vendor_Questionnaire_Form_Answers>;
};

/** input type for inserting object relation for remote table "vendor_questionnaire_form_answers" */
export type Vendor_Questionnaire_Form_Answers_Obj_Rel_Insert_Input = {
  data: Vendor_Questionnaire_Form_Answers_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Vendor_Questionnaire_Form_Answers_On_Conflict>;
};

/** on_conflict condition type for table "vendor_questionnaire_form_answers" */
export type Vendor_Questionnaire_Form_Answers_On_Conflict = {
  constraint: Vendor_Questionnaire_Form_Answers_Constraint;
  update_columns?: Array<Vendor_Questionnaire_Form_Answers_Update_Column>;
  where?: InputMaybe<Vendor_Questionnaire_Form_Answers_Bool_Exp>;
};

/** Ordering options when selecting data from "vendor_questionnaire_form_answers". */
export type Vendor_Questionnaire_Form_Answers_Order_By = {
  comment?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  field_name?: InputMaybe<Order_By>;
  form?: InputMaybe<Vendor_Questionnaire_Forms_Order_By>;
  form_answer_status?: InputMaybe<Vendor_Questionnaire_Form_Answer_Statuses_Order_By>;
  form_uploads_aggregate?: InputMaybe<Vendor_Questionnaire_Form_Uploads_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
  vendor_questionnaire_form_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: vendor_questionnaire_form_answers */
export type Vendor_Questionnaire_Form_Answers_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Vendor_Questionnaire_Form_Answers_Prepend_Input = {
  value?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "vendor_questionnaire_form_answers" */
export enum Vendor_Questionnaire_Form_Answers_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FieldName = 'field_name',
  /** column name */
  Id = 'id',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value',
  /** column name */
  VendorQuestionnaireFormId = 'vendor_questionnaire_form_id',
}

/** input type for updating data in table "vendor_questionnaire_form_answers" */
export type Vendor_Questionnaire_Form_Answers_Set_Input = {
  comment?: InputMaybe<Scalars['String']['input']>;
  field_name?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Vendor_Questionnaire_Form_Answer_Statuses_Enum>;
  value?: InputMaybe<Scalars['jsonb']['input']>;
  vendor_questionnaire_form_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "vendor_questionnaire_form_answers" */
export type Vendor_Questionnaire_Form_Answers_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Vendor_Questionnaire_Form_Answers_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Vendor_Questionnaire_Form_Answers_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  field_name?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  status?: InputMaybe<Vendor_Questionnaire_Form_Answer_Statuses_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  value?: InputMaybe<Scalars['jsonb']['input']>;
  vendor_questionnaire_form_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "vendor_questionnaire_form_answers" */
export enum Vendor_Questionnaire_Form_Answers_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  FieldName = 'field_name',
  /** column name */
  Status = 'status',
  /** column name */
  Value = 'value',
  /** column name */
  VendorQuestionnaireFormId = 'vendor_questionnaire_form_id',
}

export type Vendor_Questionnaire_Form_Answers_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Vendor_Questionnaire_Form_Answers_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Vendor_Questionnaire_Form_Answers_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Vendor_Questionnaire_Form_Answers_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Vendor_Questionnaire_Form_Answers_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Vendor_Questionnaire_Form_Answers_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Vendor_Questionnaire_Form_Answers_Set_Input>;
  /** filter the rows which have to be updated */
  where: Vendor_Questionnaire_Form_Answers_Bool_Exp;
};

/** columns and relationships of "vendor_questionnaire_form_uploads" */
export type Vendor_Questionnaire_Form_Uploads = {
  __typename?: 'vendor_questionnaire_form_uploads';
  created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  file: Files;
  file_id: Scalars['uuid']['output'];
  /** An object relationship */
  form_answer: Vendor_Questionnaire_Form_Answers;
  id: Scalars['uuid']['output'];
  type: Vq_Form_Upload_Types_Enum;
  updated_at: Scalars['timestamptz']['output'];
  vendor_questionnaire_form_answer_id: Scalars['uuid']['output'];
};

/** order by aggregate values of table "vendor_questionnaire_form_uploads" */
export type Vendor_Questionnaire_Form_Uploads_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Vendor_Questionnaire_Form_Uploads_Max_Order_By>;
  min?: InputMaybe<Vendor_Questionnaire_Form_Uploads_Min_Order_By>;
};

/** input type for inserting array relation for remote table "vendor_questionnaire_form_uploads" */
export type Vendor_Questionnaire_Form_Uploads_Arr_Rel_Insert_Input = {
  data: Array<Vendor_Questionnaire_Form_Uploads_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Vendor_Questionnaire_Form_Uploads_On_Conflict>;
};

/** Boolean expression to filter rows from the table "vendor_questionnaire_form_uploads". All fields are combined with a logical 'AND'. */
export type Vendor_Questionnaire_Form_Uploads_Bool_Exp = {
  _and?: InputMaybe<Array<Vendor_Questionnaire_Form_Uploads_Bool_Exp>>;
  _not?: InputMaybe<Vendor_Questionnaire_Form_Uploads_Bool_Exp>;
  _or?: InputMaybe<Array<Vendor_Questionnaire_Form_Uploads_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  file?: InputMaybe<Files_Bool_Exp>;
  file_id?: InputMaybe<Uuid_Comparison_Exp>;
  form_answer?: InputMaybe<Vendor_Questionnaire_Form_Answers_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  type?: InputMaybe<Vq_Form_Upload_Types_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  vendor_questionnaire_form_answer_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "vendor_questionnaire_form_uploads" */
export enum Vendor_Questionnaire_Form_Uploads_Constraint {
  /** unique or primary key constraint on columns "id" */
  VendorQuestionnaireFormUploadsPkey = 'vendor_questionnaire_form_uploads_pkey',
  /** unique or primary key constraint on columns "file_id", "vendor_questionnaire_form_answer_id" */
  VendorQuestionnaireFormUploadsVendorQuestionnaireFormAns = 'vendor_questionnaire_form_uploads_vendor_questionnaire_form_ans',
}

/** input type for inserting data into table "vendor_questionnaire_form_uploads" */
export type Vendor_Questionnaire_Form_Uploads_Insert_Input = {
  file?: InputMaybe<Files_Obj_Rel_Insert_Input>;
  file_id?: InputMaybe<Scalars['uuid']['input']>;
  form_answer?: InputMaybe<Vendor_Questionnaire_Form_Answers_Obj_Rel_Insert_Input>;
  type?: InputMaybe<Vq_Form_Upload_Types_Enum>;
  vendor_questionnaire_form_answer_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** order by max() on columns of table "vendor_questionnaire_form_uploads" */
export type Vendor_Questionnaire_Form_Uploads_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  file_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  vendor_questionnaire_form_answer_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "vendor_questionnaire_form_uploads" */
export type Vendor_Questionnaire_Form_Uploads_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  file_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  vendor_questionnaire_form_answer_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "vendor_questionnaire_form_uploads" */
export type Vendor_Questionnaire_Form_Uploads_Mutation_Response = {
  __typename?: 'vendor_questionnaire_form_uploads_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Vendor_Questionnaire_Form_Uploads>;
};

/** on_conflict condition type for table "vendor_questionnaire_form_uploads" */
export type Vendor_Questionnaire_Form_Uploads_On_Conflict = {
  constraint: Vendor_Questionnaire_Form_Uploads_Constraint;
  update_columns?: Array<Vendor_Questionnaire_Form_Uploads_Update_Column>;
  where?: InputMaybe<Vendor_Questionnaire_Form_Uploads_Bool_Exp>;
};

/** Ordering options when selecting data from "vendor_questionnaire_form_uploads". */
export type Vendor_Questionnaire_Form_Uploads_Order_By = {
  created_at?: InputMaybe<Order_By>;
  file?: InputMaybe<Files_Order_By>;
  file_id?: InputMaybe<Order_By>;
  form_answer?: InputMaybe<Vendor_Questionnaire_Form_Answers_Order_By>;
  id?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  vendor_questionnaire_form_answer_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: vendor_questionnaire_form_uploads */
export type Vendor_Questionnaire_Form_Uploads_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "vendor_questionnaire_form_uploads" */
export enum Vendor_Questionnaire_Form_Uploads_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FileId = 'file_id',
  /** column name */
  Id = 'id',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VendorQuestionnaireFormAnswerId = 'vendor_questionnaire_form_answer_id',
}

/** input type for updating data in table "vendor_questionnaire_form_uploads" */
export type Vendor_Questionnaire_Form_Uploads_Set_Input = {
  file_id?: InputMaybe<Scalars['uuid']['input']>;
  type?: InputMaybe<Vq_Form_Upload_Types_Enum>;
  vendor_questionnaire_form_answer_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "vendor_questionnaire_form_uploads" */
export type Vendor_Questionnaire_Form_Uploads_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Vendor_Questionnaire_Form_Uploads_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Vendor_Questionnaire_Form_Uploads_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  file_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  type?: InputMaybe<Vq_Form_Upload_Types_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  vendor_questionnaire_form_answer_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "vendor_questionnaire_form_uploads" */
export enum Vendor_Questionnaire_Form_Uploads_Update_Column {
  /** column name */
  FileId = 'file_id',
  /** column name */
  Type = 'type',
  /** column name */
  VendorQuestionnaireFormAnswerId = 'vendor_questionnaire_form_answer_id',
}

export type Vendor_Questionnaire_Form_Uploads_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Vendor_Questionnaire_Form_Uploads_Set_Input>;
  /** filter the rows which have to be updated */
  where: Vendor_Questionnaire_Form_Uploads_Bool_Exp;
};

/** columns and relationships of "vendor_questionnaire_forms" */
export type Vendor_Questionnaire_Forms = {
  __typename?: 'vendor_questionnaire_forms';
  /** An array relationship */
  answers: Array<Vendor_Questionnaire_Form_Answers>;
  config_snapshot?: Maybe<Scalars['FormConfig']['output']>;
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  updated_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  vendor_questionnaire: Vendor_Questionnaires;
  vendor_questionnaire_id: Scalars['uuid']['output'];
};

/** columns and relationships of "vendor_questionnaire_forms" */
export type Vendor_Questionnaire_FormsAnswersArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Questionnaire_Form_Answers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Questionnaire_Form_Answers_Order_By>>;
  where?: InputMaybe<Vendor_Questionnaire_Form_Answers_Bool_Exp>;
};

/** columns and relationships of "vendor_questionnaire_forms" */
export type Vendor_Questionnaire_FormsConfig_SnapshotArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** Boolean expression to filter rows from the table "vendor_questionnaire_forms". All fields are combined with a logical 'AND'. */
export type Vendor_Questionnaire_Forms_Bool_Exp = {
  _and?: InputMaybe<Array<Vendor_Questionnaire_Forms_Bool_Exp>>;
  _not?: InputMaybe<Vendor_Questionnaire_Forms_Bool_Exp>;
  _or?: InputMaybe<Array<Vendor_Questionnaire_Forms_Bool_Exp>>;
  answers?: InputMaybe<Vendor_Questionnaire_Form_Answers_Bool_Exp>;
  config_snapshot?: InputMaybe<Jsonb_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  vendor_questionnaire?: InputMaybe<Vendor_Questionnaires_Bool_Exp>;
  vendor_questionnaire_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** Ordering options when selecting data from "vendor_questionnaire_forms". */
export type Vendor_Questionnaire_Forms_Order_By = {
  answers_aggregate?: InputMaybe<Vendor_Questionnaire_Form_Answers_Aggregate_Order_By>;
  config_snapshot?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  vendor_questionnaire?: InputMaybe<Vendor_Questionnaires_Order_By>;
  vendor_questionnaire_id?: InputMaybe<Order_By>;
};

/** select columns of table "vendor_questionnaire_forms" */
export enum Vendor_Questionnaire_Forms_Select_Column {
  /** column name */
  ConfigSnapshot = 'config_snapshot',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VendorQuestionnaireId = 'vendor_questionnaire_id',
}

/** Streaming cursor of the table "vendor_questionnaire_forms" */
export type Vendor_Questionnaire_Forms_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Vendor_Questionnaire_Forms_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Vendor_Questionnaire_Forms_Stream_Cursor_Value_Input = {
  config_snapshot?: InputMaybe<Scalars['jsonb']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  vendor_questionnaire_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** columns and relationships of "vendor_questionnaire_statuses" */
export type Vendor_Questionnaire_Statuses = {
  __typename?: 'vendor_questionnaire_statuses';
  comment?: Maybe<Scalars['String']['output']>;
  value: Scalars['String']['output'];
  /** An array relationship */
  vendor_questionnaires: Array<Vendor_Questionnaires>;
};

/** columns and relationships of "vendor_questionnaire_statuses" */
export type Vendor_Questionnaire_StatusesVendor_QuestionnairesArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Questionnaires_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Questionnaires_Order_By>>;
  where?: InputMaybe<Vendor_Questionnaires_Bool_Exp>;
};

/** Boolean expression to filter rows from the table "vendor_questionnaire_statuses". All fields are combined with a logical 'AND'. */
export type Vendor_Questionnaire_Statuses_Bool_Exp = {
  _and?: InputMaybe<Array<Vendor_Questionnaire_Statuses_Bool_Exp>>;
  _not?: InputMaybe<Vendor_Questionnaire_Statuses_Bool_Exp>;
  _or?: InputMaybe<Array<Vendor_Questionnaire_Statuses_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
  vendor_questionnaires?: InputMaybe<Vendor_Questionnaires_Bool_Exp>;
};

export enum Vendor_Questionnaire_Statuses_Enum {
  /** The questionnaire has been created for a vendor and awaiting to be sent */
  Created = 'created',
  /** The questionnaire is due to expire */
  Expiring = 'expiring',
  /** The questionnaire is overdue and not submitted */
  Overdue = 'overdue',
  /** The questionnaire has been submitted to a vendor and awaiting their answer */
  Pending = 'pending',
  /** pending questionnaire are revoked when vendor is terminated or retired */
  Revoked = 'revoked',
  /** The questionnaire answer has been submitted by a vendor */
  Submitted = 'submitted',
}

/** Boolean expression to compare columns of type "vendor_questionnaire_statuses_enum". All fields are combined with logical 'AND'. */
export type Vendor_Questionnaire_Statuses_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Vendor_Questionnaire_Statuses_Enum>;
  _in?: InputMaybe<Array<Vendor_Questionnaire_Statuses_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Vendor_Questionnaire_Statuses_Enum>;
  _nin?: InputMaybe<Array<Vendor_Questionnaire_Statuses_Enum>>;
};

/** Ordering options when selecting data from "vendor_questionnaire_statuses". */
export type Vendor_Questionnaire_Statuses_Order_By = {
  comment?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
  vendor_questionnaires_aggregate?: InputMaybe<Vendor_Questionnaires_Aggregate_Order_By>;
};

/** select columns of table "vendor_questionnaire_statuses" */
export enum Vendor_Questionnaire_Statuses_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value',
}

/** Streaming cursor of the table "vendor_questionnaire_statuses" */
export type Vendor_Questionnaire_Statuses_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Vendor_Questionnaire_Statuses_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Vendor_Questionnaire_Statuses_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "vendor_questionnaire_uploads" */
export type Vendor_Questionnaire_Uploads = {
  __typename?: 'vendor_questionnaire_uploads';
  created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  file: Files;
  file_id: Scalars['uuid']['output'];
  id: Scalars['uuid']['output'];
  updated_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  vendor_questionnaire: Vendor_Questionnaires;
  vendor_questionnaire_id: Scalars['uuid']['output'];
  /** An array relationship */
  vendor_questionnaires_answers: Array<Vendor_Questionnaires>;
};

/** columns and relationships of "vendor_questionnaire_uploads" */
export type Vendor_Questionnaire_UploadsVendor_Questionnaires_AnswersArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Questionnaires_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Questionnaires_Order_By>>;
  where?: InputMaybe<Vendor_Questionnaires_Bool_Exp>;
};

/** order by aggregate values of table "vendor_questionnaire_uploads" */
export type Vendor_Questionnaire_Uploads_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Vendor_Questionnaire_Uploads_Max_Order_By>;
  min?: InputMaybe<Vendor_Questionnaire_Uploads_Min_Order_By>;
};

/** input type for inserting array relation for remote table "vendor_questionnaire_uploads" */
export type Vendor_Questionnaire_Uploads_Arr_Rel_Insert_Input = {
  data: Array<Vendor_Questionnaire_Uploads_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Vendor_Questionnaire_Uploads_On_Conflict>;
};

/** Boolean expression to filter rows from the table "vendor_questionnaire_uploads". All fields are combined with a logical 'AND'. */
export type Vendor_Questionnaire_Uploads_Bool_Exp = {
  _and?: InputMaybe<Array<Vendor_Questionnaire_Uploads_Bool_Exp>>;
  _not?: InputMaybe<Vendor_Questionnaire_Uploads_Bool_Exp>;
  _or?: InputMaybe<Array<Vendor_Questionnaire_Uploads_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  file?: InputMaybe<Files_Bool_Exp>;
  file_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  vendor_questionnaire?: InputMaybe<Vendor_Questionnaires_Bool_Exp>;
  vendor_questionnaire_id?: InputMaybe<Uuid_Comparison_Exp>;
  vendor_questionnaires_answers?: InputMaybe<Vendor_Questionnaires_Bool_Exp>;
};

/** unique or primary key constraints on table "vendor_questionnaire_uploads" */
export enum Vendor_Questionnaire_Uploads_Constraint {
  /** unique or primary key constraint on columns "file_id" */
  VendorQuestionnaireUploadsFileIdKey = 'vendor_questionnaire_uploads_file_id_key',
  /** unique or primary key constraint on columns "id" */
  VendorQuestionnaireUploadsPkey = 'vendor_questionnaire_uploads_pkey',
}

/** input type for inserting data into table "vendor_questionnaire_uploads" */
export type Vendor_Questionnaire_Uploads_Insert_Input = {
  file?: InputMaybe<Files_Obj_Rel_Insert_Input>;
  file_id?: InputMaybe<Scalars['uuid']['input']>;
  vendor_questionnaire_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** order by max() on columns of table "vendor_questionnaire_uploads" */
export type Vendor_Questionnaire_Uploads_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  file_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  vendor_questionnaire_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "vendor_questionnaire_uploads" */
export type Vendor_Questionnaire_Uploads_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  file_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  vendor_questionnaire_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "vendor_questionnaire_uploads" */
export type Vendor_Questionnaire_Uploads_Mutation_Response = {
  __typename?: 'vendor_questionnaire_uploads_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Vendor_Questionnaire_Uploads>;
};

/** on_conflict condition type for table "vendor_questionnaire_uploads" */
export type Vendor_Questionnaire_Uploads_On_Conflict = {
  constraint: Vendor_Questionnaire_Uploads_Constraint;
  update_columns?: Array<Vendor_Questionnaire_Uploads_Update_Column>;
  where?: InputMaybe<Vendor_Questionnaire_Uploads_Bool_Exp>;
};

/** Ordering options when selecting data from "vendor_questionnaire_uploads". */
export type Vendor_Questionnaire_Uploads_Order_By = {
  created_at?: InputMaybe<Order_By>;
  file?: InputMaybe<Files_Order_By>;
  file_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  vendor_questionnaire?: InputMaybe<Vendor_Questionnaires_Order_By>;
  vendor_questionnaire_id?: InputMaybe<Order_By>;
  vendor_questionnaires_answers_aggregate?: InputMaybe<Vendor_Questionnaires_Aggregate_Order_By>;
};

/** select columns of table "vendor_questionnaire_uploads" */
export enum Vendor_Questionnaire_Uploads_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FileId = 'file_id',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VendorQuestionnaireId = 'vendor_questionnaire_id',
}

/** Streaming cursor of the table "vendor_questionnaire_uploads" */
export type Vendor_Questionnaire_Uploads_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Vendor_Questionnaire_Uploads_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Vendor_Questionnaire_Uploads_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  file_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  vendor_questionnaire_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** placeholder for update columns of table "vendor_questionnaire_uploads" (current role has no relevant permissions) */
export enum Vendor_Questionnaire_Uploads_Update_Column {
  /** placeholder (do not use) */
  Placeholder = '_PLACEHOLDER',
}

/** columns and relationships of "vendor_questionnaires" */
export type Vendor_Questionnaires = {
  __typename?: 'vendor_questionnaires';
  /** An object relationship */
  answer_upload?: Maybe<Vendor_Questionnaire_Uploads>;
  answer_upload_id?: Maybe<Scalars['uuid']['output']>;
  created_at: Scalars['timestamptz']['output'];
  custom_message?: Maybe<Scalars['String']['output']>;
  due_date?: Maybe<Scalars['timestamptz']['output']>;
  /** An object relationship */
  form?: Maybe<Vendor_Questionnaire_Forms>;
  id: Scalars['uuid']['output'];
  /** An object relationship */
  questionnaire: Questionnaires;
  questionnaire_id: Scalars['uuid']['output'];
  /** An object relationship */
  questionnaire_status: Vendor_Questionnaire_Statuses;
  /** An object relationship */
  sent_by_user: Users;
  sent_by_user_id: Scalars['uuid']['output'];
  sent_to_email: Scalars['String']['output'];
  /** An object relationship */
  sent_to_user?: Maybe<Users>;
  sent_to_user_id?: Maybe<Scalars['uuid']['output']>;
  status: Vendor_Questionnaire_Statuses_Enum;
  subject?: Maybe<Scalars['String']['output']>;
  updated_at: Scalars['timestamptz']['output'];
  /** An array relationship */
  uploads: Array<Vendor_Questionnaire_Uploads>;
};

/** columns and relationships of "vendor_questionnaires" */
export type Vendor_QuestionnairesUploadsArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Questionnaire_Uploads_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Questionnaire_Uploads_Order_By>>;
  where?: InputMaybe<Vendor_Questionnaire_Uploads_Bool_Exp>;
};

/** order by aggregate values of table "vendor_questionnaires" */
export type Vendor_Questionnaires_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Vendor_Questionnaires_Max_Order_By>;
  min?: InputMaybe<Vendor_Questionnaires_Min_Order_By>;
};

/** Boolean expression to filter rows from the table "vendor_questionnaires". All fields are combined with a logical 'AND'. */
export type Vendor_Questionnaires_Bool_Exp = {
  _and?: InputMaybe<Array<Vendor_Questionnaires_Bool_Exp>>;
  _not?: InputMaybe<Vendor_Questionnaires_Bool_Exp>;
  _or?: InputMaybe<Array<Vendor_Questionnaires_Bool_Exp>>;
  answer_upload?: InputMaybe<Vendor_Questionnaire_Uploads_Bool_Exp>;
  answer_upload_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  custom_message?: InputMaybe<String_Comparison_Exp>;
  due_date?: InputMaybe<Timestamptz_Comparison_Exp>;
  form?: InputMaybe<Vendor_Questionnaire_Forms_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  questionnaire?: InputMaybe<Questionnaires_Bool_Exp>;
  questionnaire_id?: InputMaybe<Uuid_Comparison_Exp>;
  questionnaire_status?: InputMaybe<Vendor_Questionnaire_Statuses_Bool_Exp>;
  sent_by_user?: InputMaybe<Users_Bool_Exp>;
  sent_by_user_id?: InputMaybe<Uuid_Comparison_Exp>;
  sent_to_email?: InputMaybe<String_Comparison_Exp>;
  sent_to_user?: InputMaybe<Users_Bool_Exp>;
  sent_to_user_id?: InputMaybe<Uuid_Comparison_Exp>;
  status?: InputMaybe<Vendor_Questionnaire_Statuses_Enum_Comparison_Exp>;
  subject?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  uploads?: InputMaybe<Vendor_Questionnaire_Uploads_Bool_Exp>;
};

/** order by max() on columns of table "vendor_questionnaires" */
export type Vendor_Questionnaires_Max_Order_By = {
  answer_upload_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  custom_message?: InputMaybe<Order_By>;
  due_date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  questionnaire_id?: InputMaybe<Order_By>;
  sent_by_user_id?: InputMaybe<Order_By>;
  sent_to_email?: InputMaybe<Order_By>;
  sent_to_user_id?: InputMaybe<Order_By>;
  subject?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "vendor_questionnaires" */
export type Vendor_Questionnaires_Min_Order_By = {
  answer_upload_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  custom_message?: InputMaybe<Order_By>;
  due_date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  questionnaire_id?: InputMaybe<Order_By>;
  sent_by_user_id?: InputMaybe<Order_By>;
  sent_to_email?: InputMaybe<Order_By>;
  sent_to_user_id?: InputMaybe<Order_By>;
  subject?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "vendor_questionnaires" */
export type Vendor_Questionnaires_Mutation_Response = {
  __typename?: 'vendor_questionnaires_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Vendor_Questionnaires>;
};

/** Ordering options when selecting data from "vendor_questionnaires". */
export type Vendor_Questionnaires_Order_By = {
  answer_upload?: InputMaybe<Vendor_Questionnaire_Uploads_Order_By>;
  answer_upload_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  custom_message?: InputMaybe<Order_By>;
  due_date?: InputMaybe<Order_By>;
  form?: InputMaybe<Vendor_Questionnaire_Forms_Order_By>;
  id?: InputMaybe<Order_By>;
  questionnaire?: InputMaybe<Questionnaires_Order_By>;
  questionnaire_id?: InputMaybe<Order_By>;
  questionnaire_status?: InputMaybe<Vendor_Questionnaire_Statuses_Order_By>;
  sent_by_user?: InputMaybe<Users_Order_By>;
  sent_by_user_id?: InputMaybe<Order_By>;
  sent_to_email?: InputMaybe<Order_By>;
  sent_to_user?: InputMaybe<Users_Order_By>;
  sent_to_user_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  subject?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  uploads_aggregate?: InputMaybe<Vendor_Questionnaire_Uploads_Aggregate_Order_By>;
};

/** primary key columns input for table: vendor_questionnaires */
export type Vendor_Questionnaires_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "vendor_questionnaires" */
export enum Vendor_Questionnaires_Select_Column {
  /** column name */
  AnswerUploadId = 'answer_upload_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomMessage = 'custom_message',
  /** column name */
  DueDate = 'due_date',
  /** column name */
  Id = 'id',
  /** column name */
  QuestionnaireId = 'questionnaire_id',
  /** column name */
  SentByUserId = 'sent_by_user_id',
  /** column name */
  SentToEmail = 'sent_to_email',
  /** column name */
  SentToUserId = 'sent_to_user_id',
  /** column name */
  Status = 'status',
  /** column name */
  Subject = 'subject',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "vendor_questionnaires" */
export type Vendor_Questionnaires_Set_Input = {
  answer_upload_id?: InputMaybe<Scalars['uuid']['input']>;
  status?: InputMaybe<Vendor_Questionnaire_Statuses_Enum>;
};

/** Streaming cursor of the table "vendor_questionnaires" */
export type Vendor_Questionnaires_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Vendor_Questionnaires_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Vendor_Questionnaires_Stream_Cursor_Value_Input = {
  answer_upload_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  custom_message?: InputMaybe<Scalars['String']['input']>;
  due_date?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  questionnaire_id?: InputMaybe<Scalars['uuid']['input']>;
  sent_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  sent_to_email?: InputMaybe<Scalars['String']['input']>;
  sent_to_user_id?: InputMaybe<Scalars['uuid']['input']>;
  status?: InputMaybe<Vendor_Questionnaire_Statuses_Enum>;
  subject?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

export type Vendor_Questionnaires_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Vendor_Questionnaires_Set_Input>;
  /** filter the rows which have to be updated */
  where: Vendor_Questionnaires_Bool_Exp;
};

export type Vendors = {
  __typename?: 'vendors';
  assessment_status: Scalars['VendorAssessmentStatus']['output'];
};

export enum Vq_Form_Upload_Types_Enum {
  /** File upload is a question answer */
  Answer = 'Answer',
  /** File upload is a supporting document */
  Support = 'Support',
}

/** Boolean expression to compare columns of type "vq_form_upload_types_enum". All fields are combined with logical 'AND'. */
export type Vq_Form_Upload_Types_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Vq_Form_Upload_Types_Enum>;
  _in?: InputMaybe<Array<Vq_Form_Upload_Types_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Vq_Form_Upload_Types_Enum>;
  _nin?: InputMaybe<Array<Vq_Form_Upload_Types_Enum>>;
};
