import { Card, CardBody, Stack, Text } from '@chakra-ui/react';
import { Route } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';

import { VendorQuestionnairesTable } from '../features/vendor-questionnaires/questionnaires-table';
import { vendorLayout } from './vendorToken';

const QuestionnairesPage = () => {
  const { t } = useTranslation();

  return (
    <Stack spacing={6}>
      <Text fontSize="3xl" fontWeight="semibold">
        {t('vendors.questionnaires.heading')}
      </Text>

      <Card variant="table-styles">
        <CardBody border="none">
          <VendorQuestionnairesTable />
        </CardBody>
      </Card>
    </Stack>
  );
};

export const vendorQuestionnairesRoute = new Route({
  getParentRoute: () => vendorLayout,
  path: 'questionnaires',
  component: QuestionnairesPage,
});
